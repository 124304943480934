/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PATHS } from '../../../constants/routes';
import { useStores } from '../../../stores/index';
import MaterialTable from '../../MaterialTable/MaterialTable';
import { SearchInput } from '../../SearchInput/SearchInput';
import { Tabs } from '../../Tabs';
import { Layout } from '../Layout';

export interface OwnerOrderListLayoutProps {
  type: 'upcoming' | 'past';
  columns: any;
}

interface QueryParams {
  page: number;
  search?: string;
  past: boolean;
}

const PAGE_SIZE = 10;

export const OwnerOrderListLayout: FC<OwnerOrderListLayoutProps> = observer(
  ({ type, columns }) => {
    const { t } = useTranslation();
    const {
      skyboxStore: { getMySkyboxesOrders, mySkyboxesOrders, myOrderCount },
    } = useStores();

    const [params, setParams] = useState<QueryParams>({
      page: 0,
      search: undefined,
      past: type === 'past',
    });

    const getOrders = (params: QueryParams) => {
      getMySkyboxesOrders({
        skip: params.page * PAGE_SIZE,
        take: PAGE_SIZE,
        search: params.search,
        past: params.past,
      });
    };

    useEffect(() => {
      getOrders(params);
    }, [params]);

    const TABS = [
      {
        label: t('orders.owner.upcomingOrders'),
        path: PATHS.orders.owners.upcoming,
      },
      {
        label: t('orders.owner.pastOrders'),
        path: PATHS.orders.owners.past,
      },
    ];
    const renderTabs = () => <Tabs items={TABS} />;

    const onPageChange = async (pageIndex: number) => {
      if (pageIndex !== params.page) {
        const newParams = { ...params };
        newParams.page = pageIndex || 0;
        setParams(newParams);
      }
    };

    const onSearchChange = async (value: string) => {
      const newParams = { ...params };
      newParams.search = value;
      newParams.page = 0;
      setParams(newParams);
    };

    return (
      <Layout
        headerText={t('orders.orders')}
        headerElementsBottom={renderTabs()}
      >
        <Box p={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <Typography variant="body2">
              {t(`orders.${type}OrdersCount`, { count: myOrderCount || 0 })}
            </Typography>
            <SearchInput onChange={onSearchChange} useDebounce />
          </Box>
          <MaterialTable
            columns={columns}
            data={mySkyboxesOrders}
            onPageChange={onPageChange}
            totalCount={myOrderCount || 0}
            defaultPageSize={10}
            paginationEnabled
            useControlledState
            controlledPageIndex={params.page}
          />
        </Box>
      </Layout>
    );
  },
);
