import {
  faCalendarAlt,
  faCalendarCheck,
  faCogs,
  faCouch,
  faFileAlt,
  faInfo,
  faNewspaper,
  faTheaterMasks,
  faTicketAlt,
  faUsers,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BulletinListScreen,
  CreateBulletinScreen,
  SingleBulletinScreen,
} from '../screens/Bulletins';
import { CalendarScreen } from '../screens/Calendar';
import { DocumentsPrivacyPolicyScreen } from '../screens/Documents/DocumentsPrivacyPolicyScreen';
import { DocumentsServiceInformationScreen } from '../screens/Documents/DocumentsServiceInformationScreen';
import { DocumentsUserAgreementScreen } from '../screens/Documents/DocumentsUserAgreementScreen';
import {
  LoginScreen,
  RequestResetPasswordScreen,
  ResetPasswordScreen,
} from '../screens/Login';
import {
  AdminOrderListScreen,
  OrderDetailsScreen,
  OrderFormCustomerScreen,
  OrderFormOrderCompletedScreen,
  OrderFormPaymentFailureScreen,
  OrderFormPaymentScreen,
  OrderFormTicketsScreen,
  OrderSendTicketsScreen,
  OwnerPastOrderListScreen,
  OwnerUpcomingOrderListScreen,
} from '../screens/Orders';
import {
  AddOwnerScreen,
  EditOwnerScreen,
  OwnersScreen,
} from '../screens/Owners';
import {
  ReportsAdminListScreen,
  ReportsMainScreen,
  ReportsOwnerListScreen,
} from '../screens/Reports';
import { SettingsScreen } from '../screens/Settings';
import {
  AddSkyboxScreen,
  EditSkyboxScreen,
  SkyboxesScreen,
} from '../screens/Skyboxes';
import {
  AllEventsScreen,
  EventInformationScreen,
  EventListScreen,
  EventSerieListScreen,
  EventSeriesSettingsScreen,
  EventSettingsScreen,
  EventSkyboxesScreen,
} from '../screens/SkyboxEvents';
import { UsersScreen } from '../screens/Users';
import { IRoute } from '../types/Common';
import { UserRole } from '../types/User';

export const PATHS = {
  bulletins: {
    main: '/bulletins',
    single: '/bulletins/:id',
    add: '/bulletins/add-new',
  },
  skyboxes: {
    main: '/skyboxes',
    add: '/skybox/add-new',
    single: '/skybox/:id',
  },
  skyboxEvents: {
    eventSeriesList: '/skybox-events',
    eventList: '/skybox-events/:id/events',
    eventInfo: '/skybox-events/:eventSeriesId/:eventId',
    eventSettings: '/skybox-events/:eventSeriesId/:eventId/settings',
    eventSkyboxes: '/skybox-events/:eventSeriesId/:eventId/skyboxes',
    eventSeriesSettings: '/skybox-events/:id/settings',
    allEvents: '/skybox-events/all',
  },
  owners: {
    main: '/owners',
    add: '/owner/add-new',
    single: '/owner/:id',
  },
  orders: {
    main: '/orders',
    skybox: {
      main: '/skybox-orders',
      details: '/skybox-orders/:livexOrderNumber/details',
      sendTickets: '/skybox-orders/:livexOrderNumber/sendTickets',
    },
    add: {
      tickets: '/add-new-order/tickets',
      customer: '/add-new-order/customer',
      payment: '/add-new-order/payment',
      paymentFailure: '/add-new-order/payment-failure',
      orderCompleted: '/add-new-order/order-completed',
    },
    owners: {
      upcoming: '/orders/owner',
      past: '/orders/owner/past',
      details: '/orders/owner/:livexOrderNumber/details',
      sendTickets: '/orders/owner/:livexOrderNumber/sendTickets',
    },
  },
  calendar: {
    main: '/calendar',
  },
  users: {
    main: '/users',
  },
  settings: {
    main: '/settings',
  },
  skyboxReports: {
    main: '/reports',
    list: '/reports/list',
    owners: {
      list: '/reports/owners/list',
    },
  },
  auth: {
    login: '/login',
    forgotPassword: '/forgot-password',
    passwordReset: '/password-reset/:userId/:token',
  },
  documents: {
    serviceInformation: '/documents/serviceInformation',
    userAgreement: '/documents/userAgreement',
    privacyPolicy: '/documents/privacyPolicy',
  },
} as const;

const {
  orders: {
    add: { tickets, customer, payment, orderCompleted },
  },
} = PATHS;

export interface BookingSideBarStepItem {
  label: string;
  href: string;
  id: string;
}

export const BOOKING_SIDEBAR_STEPS: BookingSideBarStepItem[] = [
  { label: 'orders.add.sidebar.tickets', href: tickets, id: 'tickets' },
  { label: 'orders.add.sidebar.customer', href: customer, id: 'customer' },
  { label: 'orders.add.sidebar.payment', href: payment, id: 'payment' },
  {
    label: 'orders.add.sidebar.orderCompleted',
    href: orderCompleted,
    id: 'orderCompleted',
  },
];

const BASIC_ADMIN_ROLES: UserRole[] = [
  'SuperAdmin',
  'SuperManager',
  'Manager',
  'Admin',
  'SkyboxAdmin',
];
const BASIC_ADMIN_ROLES_AND_OWNER: UserRole[] = [
  ...BASIC_ADMIN_ROLES,
  ...(['SkyboxOwner'] as UserRole[]),
];

/**
 * AUTH
 */
const login: IRoute[] = [
  {
    name: 'login',
    href: PATHS.auth.login,
    component: LoginScreen,
    nonAuth: true,
  },
  {
    name: 'forgot-password',
    href: PATHS.auth.forgotPassword,
    component: RequestResetPasswordScreen,
    nonAuth: true,
  },
  {
    name: 'password-reset',
    href: PATHS.auth.passwordReset,
    component: ResetPasswordScreen,
    nonAuth: true,
  },
];

/**
 * SKYBOXES
 */
const skyboxes: IRoute[] = [
  {
    menuGroup: 'skybox',
    name: 'skyboxes',
    href: PATHS.skyboxes.main,
    component: SkyboxesScreen,
    menuItem: true,
    iconComponent: <FontAwesomeIcon icon={faCouch} />,
    roles: BASIC_ADMIN_ROLES_AND_OWNER,
  },
  {
    menuGroup: 'skybox',
    name: 'add-skybox',
    href: PATHS.skyboxes.add,
    component: AddSkyboxScreen,
    nonAuth: false,
    roles: BASIC_ADMIN_ROLES,
  },
  {
    menuGroup: 'skybox',
    name: 'edit-skybox',
    href: PATHS.skyboxes.single,
    component: EditSkyboxScreen,
    nonAuth: false,
    roles: BASIC_ADMIN_ROLES_AND_OWNER,
  },
];

/**
 * OWNERS
 */
const owners: IRoute[] = [
  {
    menuGroup: 'owner',
    name: 'owners',
    href: PATHS.owners.main,
    component: OwnersScreen,
    menuItem: true,
    iconComponent: <FontAwesomeIcon icon={faUserTie} />,
    roles: BASIC_ADMIN_ROLES,
  },
  {
    menuGroup: 'owner',
    name: 'add-owner',
    href: PATHS.owners.add,
    component: AddOwnerScreen,
    nonAuth: false,
    roles: BASIC_ADMIN_ROLES,
  },
  {
    menuGroup: 'owner',
    name: 'edit-owner',
    href: PATHS.owners.single,
    component: EditOwnerScreen,
    roles: ['SkyboxAdmin'],
  },
];

/**
 * CALENDAR
 */
const calendar: IRoute[] = [
  {
    menuGroup: 'calendar',
    name: 'calendar',
    href: PATHS.calendar.main,
    component: CalendarScreen,
    menuItem: true,
    iconComponent: <FontAwesomeIcon icon={faCalendarAlt} />,
  },
];

/**
 * USERS
 */
const users: IRoute[] = [
  {
    menuGroup: 'user',
    name: 'users',
    href: PATHS.users.main,
    component: UsersScreen,
    menuItem: true,
    iconComponent: <FontAwesomeIcon icon={faUsers} />,
    roles: BASIC_ADMIN_ROLES_AND_OWNER,
  },
];

/**
 * BULLETINS
 */
const bulletins: IRoute[] = [
  {
    menuGroup: 'bulletin',
    name: 'bulletins',
    exact: true,
    href: PATHS.bulletins.main,
    component: BulletinListScreen,
    menuItem: true,
    iconComponent: <FontAwesomeIcon icon={faNewspaper} />,
    roles: BASIC_ADMIN_ROLES_AND_OWNER,
  },
  {
    menuGroup: 'bulletin',
    name: 'add-bulletin',
    href: PATHS.bulletins.add,
    component: CreateBulletinScreen,
    nonAuth: false,
    roles: BASIC_ADMIN_ROLES,
  },
  {
    menuGroup: 'bulletin',
    name: 'edit-bulletin',
    href: PATHS.bulletins.single,
    component: SingleBulletinScreen,
    roles: BASIC_ADMIN_ROLES_AND_OWNER,
  },
];

/**
 * REPORTS
 */
const skyboxReports: IRoute[] = [
  {
    menuGroup: 'reports',
    name: 'reports',
    href: PATHS.skyboxReports.main,
    component: ReportsMainScreen,
    exact: true,
    menuItem: true,
    iconComponent: <FontAwesomeIcon icon={faFileAlt} />,
    roles: BASIC_ADMIN_ROLES_AND_OWNER,
  },
  {
    menuGroup: 'reports',
    name: 'reports-list',
    exact: true,
    href: PATHS.skyboxReports.list,
    component: ReportsAdminListScreen,
    nonAuth: false,
    roles: [...BASIC_ADMIN_ROLES, 'SuperOwner', 'SkyboxAdmin'],
  },
  {
    menuGroup: 'reports',
    name: 'reports-owner-list',
    exact: true,
    href: PATHS.skyboxReports.owners.list,
    component: ReportsOwnerListScreen,
    roles: ['SkyboxOwner', 'SuperOwner'],
  },
];

/**
 * SETTINGS
 */
const settings: IRoute[] = [
  {
    menuGroup: 'settings',
    name: 'settings',
    href: PATHS.settings.main,
    component: SettingsScreen,
    menuItem: true,
    iconComponent: <FontAwesomeIcon icon={faCogs} />,
    roles: ['SkyboxOwner'],
  },
];

const documents: IRoute[] = [
  {
    menuGroup: 'documents',
    name: 'documents-serviceInformation',
    href: PATHS.documents.serviceInformation,
    component: DocumentsServiceInformationScreen,
    menuItem: true,
    iconComponent: <FontAwesomeIcon icon={faInfo} />,
  },
  {
    menuGroup: 'documents',
    name: 'documents-userAgreement',
    href: PATHS.documents.userAgreement,
    component: DocumentsUserAgreementScreen,
  },
  {
    menuGroup: 'documents',
    name: 'documents-privacyPolicy',
    href: PATHS.documents.privacyPolicy,
    component: DocumentsPrivacyPolicyScreen,
  },
];

export const skyboxEvents: IRoute[] = [
  {
    menuGroup: 'skyboxEvent',
    name: 'events',
    href: PATHS.skyboxEvents.eventSeriesList,
    component: EventSerieListScreen,
    menuItem: true,
    iconComponent: <FontAwesomeIcon icon={faCalendarCheck} />,
    exact: true,
    roles: ['SkyboxAdmin'],
  },
  {
    menuGroup: 'skyboxEvent',
    name: 'all-events',
    href: PATHS.skyboxEvents.allEvents,
    component: AllEventsScreen,
    menuItem: true,
    iconComponent: <FontAwesomeIcon icon={faCalendarCheck} />,
    exact: true,
    roles: ['SkyboxOwner'],
  },
  {
    menuGroup: 'skyboxEvent',
    name: 'event-series-settings',
    href: PATHS.skyboxEvents.eventSeriesSettings,
    component: EventSeriesSettingsScreen,
    roles: ['SkyboxAdmin'],
  },
  {
    menuGroup: 'skyboxEvent',
    name: 'event-settings',
    href: PATHS.skyboxEvents.eventSettings,
    component: EventSettingsScreen,
    roles: ['SkyboxAdmin'],
  },
  {
    menuGroup: 'skyboxEvent',
    name: 'event-list',
    href: PATHS.skyboxEvents.eventList,
    component: EventListScreen,
    roles: ['SkyboxAdmin'],
  },
  {
    menuGroup: 'skyboxEvent',
    name: 'event-info',
    href: PATHS.skyboxEvents.eventInfo,
    component: EventInformationScreen,
    exact: true,
  },
  {
    menuGroup: 'skyboxEvent',
    name: 'event-skyboxes',
    href: PATHS.skyboxEvents.eventSkyboxes,
    component: EventSkyboxesScreen,
    exact: true,
  },
];

const BOOKING_BASE = {
  menuGroup: 'order',
  exact: true,
};

export const newOrder: IRoute[] = [
  {
    name: 'add-order-tickets',
    href: PATHS.orders.add.tickets,
    component: OrderFormTicketsScreen,
  },
  {
    name: 'add-order-customer',
    href: PATHS.orders.add.customer,
    component: OrderFormCustomerScreen,
  },
  {
    name: 'add-order-payment',
    href: PATHS.orders.add.payment,
    component: OrderFormPaymentScreen,
  },
  {
    name: 'add-order-order-completed',
    href: PATHS.orders.add.orderCompleted,
    component: OrderFormOrderCompletedScreen,
  },
  {
    name: 'add-order-payment-failure',
    href: PATHS.orders.add.paymentFailure,
    component: OrderFormPaymentFailureScreen,
  },
];

export const skyboxOrders: IRoute[] = [
  {
    name: 'orders',
    href: PATHS.orders.skybox.main,
    component: AdminOrderListScreen,
    menuItem: true,
    iconComponent: <FontAwesomeIcon icon={faTicketAlt} />,
    roles: ['SkyboxAdmin'] as UserRole[],
  },
  {
    name: 'order-details',
    href: PATHS.orders.skybox.details,
    component: OrderDetailsScreen,
    roles: ['SkyboxAdmin'] as UserRole[],
  },
  {
    name: 'order-send-tickets',
    href: PATHS.orders.skybox.sendTickets,
    component: OrderSendTicketsScreen,
    roles: ['SkyboxAdmin'] as UserRole[],
  },
  ...newOrder,
].map((obj) => ({ ...BOOKING_BASE, ...obj }));

const OWNER_BOOKINGS_BASE = {
  menuGroup: 'order',
  exact: true,
  roles: ['SkyboxOwner'] as UserRole[],
};

export const ownerOrders: IRoute[] = [
  {
    name: 'owner-orders',
    href: PATHS.orders.owners.upcoming,
    component: OwnerUpcomingOrderListScreen,
    iconComponent: <FontAwesomeIcon icon={faTheaterMasks} />,
    menuItem: true,
  },
  {
    name: 'owner-orders-past',
    href: PATHS.orders.owners.past,
    component: OwnerPastOrderListScreen,
  },
  {
    name: 'owner-order-details',
    href: PATHS.orders.owners.details,
    component: OrderDetailsScreen,
  },
  {
    name: 'owner-order-send-tickets',
    href: PATHS.orders.owners.sendTickets,
    component: OrderSendTicketsScreen,
  },
].map((obj) => ({ ...obj, ...OWNER_BOOKINGS_BASE }));

const baseModules = [...login, ...settings, ...documents];

const additionalModules = [
  ...skyboxEvents,
  ...bulletins,
  ...skyboxes,
  ...owners,
  ...calendar,
  ...skyboxOrders,
  ...ownerOrders,
  ...users,
  ...skyboxReports,
] as IRoute[];

export const ROUTES = [...additionalModules, ...baseModules];
