import colors from './colors';

export const getPickerOverrides = (accentColor: string) => {
  return {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: accentColor,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        padding: 8,
      },
    },
    MuiPickersDay: {
      day: {
        color: colors.text.body1,
      },
      daySelected: {
        backgroundColor: accentColor,
      },
      dayDisabled: {
        color: colors.text.body2,
      },
      current: {
        color: 'white',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: accentColor,
      },
    },
  };
};
