import { makeAutoObservable, runInAction } from 'mobx';
import { ROUTES } from '../constants';
import CustomerService from '../services/CustomerService';
import { IRoute, LivexDocumentType, StoreState } from '../types/Common';
import { getAuthToken } from '../utils/auth';
import AuthStore from './AuthStore';

export interface ICommonStore {
  isAuthenticated: boolean | undefined;
  uiState: {
    mobileDrawerActive: boolean;
  };
  state: StoreState;
  currentRoutes: IRoute[];
  isLoading: boolean;
  authBlockedPath?: string;
  menuCollapsed: boolean;
  updateIsAuthenticated: (isAuthenticated: boolean | undefined) => void;
  updateCurrentRoutes: () => void;
  setAuthBlockedPath: (path?: string) => void;
}

class CommonModel implements ICommonStore {
  state: StoreState = 'Idle';
  menuCollapsed: ICommonStore['menuCollapsed'] = false;
  authBlockedPath?: string = undefined;
  isAuthenticated: boolean | undefined = undefined;
  currentRoutes: IRoute[] = [];
  documents: { [T in LivexDocumentType]: string | undefined } = {
    skyboxAdminServiceInformation: undefined,
    skyboxAdminUserAgreement: undefined,
    skyboxAdminPrivacyPolicy: undefined,
  };

  uiState: ICommonStore['uiState'] = {
    mobileDrawerActive: false,
  };

  constructor() {
    this.isAuthenticated = !!getAuthToken();
    makeAutoObservable(this);
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  setMenuCollapsed = (isCollapsed: boolean) => {
    this.menuCollapsed = isCollapsed;
  };

  toggleMobileDrawer = ({ isOpen }: { isOpen: boolean }) => {
    this.uiState.mobileDrawerActive = isOpen;
    if (isOpen) this.menuCollapsed = false;
  };

  /**
   * Sets a path that was blocked by login screen
   */
  setAuthBlockedPath = (path?: string) => {
    this.authBlockedPath = path;
  };

  /**
   * Updates the auth token used in http requests
   */
  updateIsAuthenticated = (isAuthenticated: boolean | undefined) => {
    this.isAuthenticated = isAuthenticated;
  };

  /**
   * Updates current routes by logged in user role
   */
  updateCurrentRoutes = () => {
    this.currentRoutes = ROUTES.filter((route) => {
      // If not authenticated, filter routes that requires authentication
      if (!AuthStore.currentUser) {
        return !!route.nonAuth;
      }
      // If authenticated, filter routes that are for non authenticated users
      if (route.nonAuth) {
        return false;
      }

      let ok = true;
      // Filter routes that requires role that user doesn't have
      if (route.roles && route.roles.length) {
        ok = false;
        const currentRoles = [...AuthStore.currentUserRoles];
        if (!currentRoles.length) {
          return ok;
        }
        route.roles.forEach((role) => {
          if (
            currentRoles.includes(role) ||
            currentRoles.includes('SuperAdmin')
          ) {
            ok = true;
          }
        });
      }
      return ok;
    });
  };

  /**
   * Gets document by type
   */
  getDocument = async (type: LivexDocumentType, locale: string) => {
    this.state = 'Loading';

    try {
      const res = await CustomerService.getDocument(type, locale);
      runInAction(() => {
        this.documents[type] = res?.data?.result;
        this.state = 'Success';
      });

      return res;
    } catch (error) {
      this.state = 'Error';
      throw error;
    }
  };
}

const CommonStore = new CommonModel();

export default CommonStore;
