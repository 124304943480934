import groupBy from 'lodash.groupby';
import { autorun, set, toJS } from 'mobx';
import { useEffect, useState } from 'react';
import { GroupedArrayItem } from '../types/Common';
import {
  ISkyboxWithEventSkybox,
  MasterSkyboxStatus,
  SkyboxStatus,
} from '../types/Skybox';
import { getPrettySum } from './i18nUtils';

/* eslint-disable no-param-reassign */
export const arrayMove = (arr: any[], old_index: number, new_index: number) => {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

/**
 * Checks if component is rendered by client / server
 */
export const useIsClient = () => {
  const [isClient, setClient] = useState(false);
  const key = isClient ? 'client' : 'server';

  useEffect(() => {
    setClient(true);
  }, []);

  return { isClient, key };
};

/**
 * Automatically saves store state into localStorage
 */
export const storeAutoSave = (
  _this: any,
  storeName: string,
  omittedProps?: string[],
) => {
  const storedJson = localStorage.getItem(storeName);
  if (storedJson) {
    set(_this, JSON.parse(storedJson));
  }
  autorun(() => {
    const storeToSave = toJS(_this);
    if (omittedProps) {
      omittedProps?.forEach((prop) => {
        delete storeToSave[prop];
      });
    }
    localStorage.setItem(storeName, JSON.stringify(storeToSave));
  });
};

/**
 * Find item from array of objects
 * key defaults to 'id'
 */

export const findById = (list: any[], value: any, key?: string): any => {
  let item;
  for (let i = 0; i < list.length; i++) {
    if (list[i][key || 'id'] === value) {
      item = list[i];
      break;
    }
  }
  return item;
};

export const getGroupedItems = (
  items: any[],
  property: string,
): GroupedArrayItem[] => {
  const groupObj = groupBy(items, (item: any) => {
    return property.split('.').reduce((a, b) => a[b], item);
  });

  return (
    Object.keys(groupObj)
      .map((name: string) => {
        return {
          id: name,
          title: name,
          quantity: groupObj[name]?.length ?? 0,
          children: groupObj[name] ?? [],
        };
      })
      // @ts-ignore
      .sort((a: any, b: any) => {
        if (a.title < b.title) {
          return -1;
        }
        return 1;
      })
  );
};

export const getSkyboxStatus = (item: ISkyboxWithEventSkybox): SkyboxStatus => {
  let text: SkyboxStatus = 'blank';

  const isReservedForOwnUse =
    item.type === 'not_for_sale' || item.eventSkybox?.type === 'not_for_sale';
  const isDisabled =
    item.type === 'disabled' || item.eventSkybox?.type === 'disabled';
  const isReleased =
    item.vacantForEvent && item.eventSkybox?.type !== 'targeted_rental';
  const isTargeted = item?.eventSkybox?.type === 'targeted_rental';
  const isGroupSale = item.type === 'group_sale';
  const isRented = item.rented && item.purchase?.paidOn;
  const isInCart = item.rented && item.purchase && !item.purchase?.paidOn;
  const isNoSelection =
    item.vacantForEvent && item.eventSkybox?.type === 'no_selection';

  if (isReservedForOwnUse) {
    text = 'not_for_sale';
    if (isRented) {
      text = 'not_for_sale_with_purchase';
    }
    if (isInCart) {
      text = 'not_for_sale_in_cart';
    }
  } else if (isRented) {
    text = 'rented';
  } else if (isInCart) {
    text = 'in_cart';
  } else if (isReleased) {
    text = 'rerent';
  } else if (isDisabled) {
    text = 'disabled';
  } else if (isGroupSale) {
    text = 'group_sale';
  } else if (isTargeted) {
    text = 'targeted_rental';
  }

  if (!item.eventSkybox || isNoSelection) {
    text = 'blank';
  }

  return text;
};

export const getMasterSkyboxStatus = (
  skyboxStatus: SkyboxStatus,
): MasterSkyboxStatus => {
  let status = 'free' as MasterSkyboxStatus;

  if (
    skyboxStatus === 'not_for_sale_with_purchase' ||
    skyboxStatus === 'rented'
  ) {
    status = 'reserved';
  }

  if (
    skyboxStatus === 'not_for_sale' ||
    skyboxStatus === 'in_cart' ||
    skyboxStatus === 'not_for_sale_in_cart'
  ) {
    status = 'waiting';
  }

  if (skyboxStatus === 'disabled') {
    status = 'disabled';
  }
  return status;
};

export const getPropertySum = (
  arr: any,
  property?: string,
  returnPlain?: boolean,
) => {
  const sum = arr.reduce((a: any, b: any) => a + b[property || 'price'], 0);
  if (returnPlain) return sum;
  return getPrettySum(sum, true);
};
