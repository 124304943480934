import { IBulletin } from '../types/Bulletin';
import { PaginationDTO } from '../types/Common';
import { api, getPath } from './api';

const API_PATHS = Object.freeze({
  base: '/customer/{customerId}/bulletin',
  single: '/customer/{customerId}/bulletin/{itemId}',
  markAllRead: '/customer/{customerId}/bulletin/read',
  markRead: '/customer/{customerId}/bulletin/read/{itemId}',
});

class BulletinServiceModel {
  getBulletins = async (params: PaginationDTO) => {
    const res = await api.get(getPath(API_PATHS.base), { params });
    return res?.data;
  };

  getBulletin = async (id: string) => {
    const res = await api.get(getPath(API_PATHS.single, id));
    return res?.data;
  };

  createBulletin = async (data: IBulletin) =>
    api.post(getPath(API_PATHS.base), data);

  updateBulletin = async (id: string, data: IBulletin) =>
    api.put(getPath(API_PATHS.single, id), data);

  deleteBulletin = async (id: string) =>
    api.delete(getPath(API_PATHS.single, id));

  markAllBulletinsRead = async () =>
    api.post(getPath(API_PATHS.markAllRead), undefined);

  markBulletinRead = async (id: string) =>
    api.post(getPath(API_PATHS.markRead, id), undefined);
}

const BulletinService = new BulletinServiceModel();

export default BulletinService;
