import { Box, Button, Typography } from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';
import sortBy from 'lodash.sortby';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { SkyboxEventLayout } from '../../components/Layout/hocs/SkyboxEventLayout';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { SkyboxSettingsModal } from '../../components/Modals/hocs/SkyboxSettingsModal/SkyboxSettingsModal';
import { SkyboxStatusText } from '../../components/SkyboxStatusText';
import { PATHS } from '../../constants/routes';
import { useStores } from '../../stores';
import colors from '../../styles/colors';
import { ISkybox, ISkyboxWithEventSkybox } from '../../types/Skybox';
import { IAdminUser } from '../../types/User';
import {
  getMasterSkyboxStatus,
  getSkyboxStatus,
} from '../../utils/commonUtils';

const isUsersUnpaidSkyboxInCart = (
  eventSkybox: ISkyboxWithEventSkybox,
  user: IAdminUser | undefined,
) => {
  return (
    !eventSkybox?.purchase?.paidOn &&
    eventSkybox?.purchase?.adminUserId &&
    eventSkybox.purchase.adminUserId === user?.id
  );
};

export const EventSkyboxesScreen: React.FC = observer(() => {
  const { eventId } = useParams() as { eventId: string };
  const [selected, setSelected] = useState<ISkybox | undefined>();

  const {
    skyboxStore: {
      getMyEventSkyboxes,
      getEventSkyboxes,
      myEventSkyboxes,
      eventSkyboxes,
    },
    authStore: { isOwner, isAdmin, currentUser },
    cartStore: {
      setEventIds,
      setActiveStep,
      setSkyboxId,
      addSkyboxToCart,
      createCart,
      clearCartFromApi,
    },
    eventStore: { event },
    settingsStore: { myCompanyData },
  } = useStores();

  const { t } = useTranslation();
  const history = useHistory();

  const skyboxes = useMemo(() => {
    if (isOwner)
      return myEventSkyboxes?.filter((skybox) => {
        if (
          skybox?.eventSkybox?.secondaryOwnerId &&
          skybox?.eventSkybox?.secondaryOwnerId !== myCompanyData?.id
        )
          return false;
        return (
          skybox.ownerCompanyId === myCompanyData?.id ||
          skybox?.eventSkybox?.secondaryOwnerId === myCompanyData?.id
        );
      });

    const sortedEventSkyboxes = sortBy(eventSkyboxes, ['name', 'mapId']);

    return sortedEventSkyboxes;
  }, [isOwner, myEventSkyboxes, eventSkyboxes, myCompanyData]);

  useEffect(() => {
    if (isOwner) {
      getMyEventSkyboxes(eventId);
    } else {
      getEventSkyboxes(eventId);
    }
  }, [eventId, getEventSkyboxes, getMyEventSkyboxes, isOwner]);

  const renderStatusCell = useCallback(
    (skybox: ISkyboxWithEventSkybox) => {
      let iconColor = colors.red;
      const skyboxStatus = getSkyboxStatus(skybox);
      const masterSkyboxStatus = getMasterSkyboxStatus(skyboxStatus);

      let status = 'vacantForEvent';
      if (skybox.purchase || skybox.rented) status = 'reserved';
      if (skybox.eventSkybox?.type === 'disabled') status = 'disabled';

      if (masterSkyboxStatus === 'free') {
        iconColor = colors.green;
      } else if (masterSkyboxStatus === 'reserved') {
        iconColor = colors.red3;
      } else if (masterSkyboxStatus === 'waiting') {
        iconColor = colors.gold7;
      }

      const Icon =
        status !== 'reserved' && status !== 'disabled' ? (
          <CheckCircle style={{ color: iconColor }} />
        ) : (
          <Cancel style={{ color: iconColor }} />
        );

      return (
        <Box display="flex">
          {Icon}
          <Box mr={1} />
          <Typography>
            {t(`skybox.masterStatus.${masterSkyboxStatus}`)}
          </Typography>
        </Box>
      );
    },
    [t],
  );

  const hasPaidPurchase = (eventSkybox: ISkyboxWithEventSkybox) => {
    if (!eventSkybox?.purchase) return false;
    return !!(eventSkybox.purchase.paidOn || eventSkybox.purchase.orderedOn);
  };

  const onClearFromCartClick = useCallback(
    (eventSkybox: ISkyboxWithEventSkybox) => async () => {
      if (
        eventSkybox.purchase &&
        isUsersUnpaidSkyboxInCart(eventSkybox, currentUser)
      ) {
        await clearCartFromApi(eventSkybox.purchase.id);
        await getMyEventSkyboxes(eventId);
      }
    },
    [currentUser, eventId, clearCartFromApi, getMyEventSkyboxes],
  );

  const onMakeOrderClick = useCallback(
    (eventSkybox: ISkyboxWithEventSkybox) => async () => {
      setEventIds({
        eventId: event?.id,
        eventSeriesId: event?.eventSeriesId,
      });
      setActiveStep(0);
      setSkyboxId(eventSkybox.id);

      if (eventSkybox.type === 'whole_sale') {
        // Create cart as a child purchase
        if (hasPaidPurchase(eventSkybox)) {
          await createCart(eventSkybox.purchase?.id);
        }
        // Create a new cart and add skybox to it
        else {
          await addSkyboxToCart();
        }
      }
      history.push(PATHS.orders.add.tickets);
    },
    [
      addSkyboxToCart,
      setActiveStep,
      setEventIds,
      createCart,
      event,
      history,
      setSkyboxId,
    ],
  );

  const onManageSkyboxClick = (eventSkybox: ISkybox) => () => {
    setSelected(eventSkybox);
  };

  const onClose = () => {
    setSelected(undefined);
  };

  const COLUMNS = useMemo(
    () => [
      {
        accessor: 'name',
        Header: t('common.skybox'),
        width: 200,
      },
      {
        accessor: 'seatCount',
        Header: t('common.capacity'),
        width: 30,
      },
      {
        accessor: 'status',
        Header: t('common.status'),
        width: 50,
        Cell: ({
          row: { original },
        }: {
          row: { original: ISkyboxWithEventSkybox };
        }) => renderStatusCell(original),
      },
      {
        accessor: 'usage',
        Header: t('common.usage'),
        width: 75,
        Cell: ({
          row: { original },
        }: {
          row: { original: ISkyboxWithEventSkybox };
        }) => t(`skybox.status.${getSkyboxStatus(original)}`),
      },
      {
        accessor: 'x',
        Header: t('common.description'),
        width: 250,
        Cell: ({
          row: { original },
        }: {
          row: { original: ISkyboxWithEventSkybox };
        }) => {
          return <SkyboxStatusText skybox={original} />;
        },
      },
      {
        accessor: 'action',
        Header: t('common.action'),
        Cell: ({
          row: { original },
        }: {
          row: { original: ISkyboxWithEventSkybox };
        }) => {
          const status = getSkyboxStatus(original);
          const disabled =
            status === 'rented' ||
            hasPaidPurchase(original) ||
            status === 'disabled' ||
            (isAdmin && !original.secondaryOwners?.length);

          const buyTicketsText = hasPaidPurchase(original)
            ? 'events.makeChildOrder'
            : 'events.makeOrder';
          return (
            <Box display="flex">
              <Button
                disabled={disabled}
                onClick={onManageSkyboxClick(original)}
                variant="outlined"
              >
                {t('common.manage')}
              </Button>
              {isOwner &&
                (status === 'not_for_sale' ||
                  status === 'not_for_sale_with_purchase') && (
                  <>
                    <Box mr={1} />
                    <Button
                      variant="outlined"
                      onClick={onMakeOrderClick(original)}
                    >
                      {t(buyTicketsText)}
                    </Button>
                  </>
                )}

              {isOwner &&
                status === 'not_for_sale_in_cart' &&
                isUsersUnpaidSkyboxInCart(original, currentUser) && (
                  <>
                    <Box mr={1} />
                    <Button
                      variant="outlined"
                      onClick={onClearFromCartClick(original)}
                    >
                      {t('events.clearCart')}
                    </Button>
                  </>
                )}
            </Box>
          );
        },
      },
    ],
    [
      isOwner,
      onMakeOrderClick,
      onClearFromCartClick,
      renderStatusCell,
      t,
      isAdmin,
      currentUser,
    ],
  );

  const getRowProps = (row: any) => {
    if (!myCompanyData) return {};
    if (row?.original?.ownerCompanyId !== myCompanyData?.id)
      return { style: { fontStyle: 'italic' } };
    return {};
  };

  return (
    <SkyboxEventLayout>
      <Typography variant="body2">
        {t('skybox.skyboxCount', { count: skyboxes?.length || 0 })}
      </Typography>
      <Box mb={3} />
      <MaterialTable
        data={skyboxes}
        columns={COLUMNS}
        getRowProps={getRowProps}
      />
      {!!selected && (
        <SkyboxSettingsModal
          open={!!selected}
          skybox={selected as ISkyboxWithEventSkybox}
          onClose={onClose}
        />
      )}
    </SkyboxEventLayout>
  );
});
