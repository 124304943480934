import { Box, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  spinnerContainer: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    top: '50%',
    zIndex: 3,
    width: 80,
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 500,
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
});

export const LoadingSpinner = () => (
  <Box className={useStyles().spinnerContainer}>
    <CircularProgress color="secondary" />
  </Box>
);
