import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { PATHS } from './constants/routes';
import LoadingScreen from './screens/Loading/LoadingScreen';
import { useStores } from './stores';

const SentryRoute = Sentry.withSentryRouting(Route);

const Routes = observer(() => {
  const {
    authStore: {
      isInitialized,
      currentUserRoles,
      isAuthLoading,
      refreshIsAuthenticated,
      isOwner,
      isAdmin,
      isJanitor,
      isManager,
    },
    commonStore: {
      currentRoutes,
      updateCurrentRoutes,
      setAuthBlockedPath,
      authBlockedPath,
    },
  } = useStores();
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;

  // On initial load try to refresh user and customer data
  useEffect(() => {
    refreshIsAuthenticated();
  }, [refreshIsAuthenticated]);

  const hasAdminPermissions = isOwner || isAdmin || isManager;

  useEffect(() => {
    if (hasAdminPermissions) {
      if (authBlockedPath === currentPath) {
        setAuthBlockedPath(undefined);
      }
    }
  }, [hasAdminPermissions, authBlockedPath, currentPath, setAuthBlockedPath]);

  // Update accessible routes when
  // - Current user is fully initialized on page reload
  // - User roles change when user logs in or out
  useEffect(() => {
    if (isInitialized) {
      updateCurrentRoutes();
    }
  }, [isInitialized, currentUserRoles, updateCurrentRoutes]);

  if (currentRoutes.length === 0 || isAuthLoading) {
    return <LoadingScreen />;
  }

  let defaultRoute: string = isOwner
    ? PATHS.skyboxEvents.allEvents
    : PATHS.skyboxEvents.eventSeriesList;

  if (!hasAdminPermissions) {
    if (location.pathname !== PATHS.auth.login) {
      setAuthBlockedPath(currentPath);
    }
    defaultRoute = PATHS.auth.login;
  } else if (authBlockedPath) {
    defaultRoute = authBlockedPath;
  }

  if (isJanitor) {
    defaultRoute = PATHS.calendar.main;
  }

  return (
    <Switch>
      {currentRoutes.map((route) => {
        const Screen = route.component;
        return (
          <SentryRoute key={route.href} path={route.href} exact={route.exact}>
            <Screen />
          </SentryRoute>
        );
      })}
      <Redirect to={defaultRoute} />
    </Switch>
  );
});

export default Routes;
