import { Grid, makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { Layout } from '../../components/Layout';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';

interface LoadingScreenProps {}

const useStyles = makeStyles({
  fullHeight: {
    height: '100vh',
    width: '100vw',
    background: 'white',
  },
  center: {
    textAlign: 'center',
  },
});

const LoadingScreen: FC<LoadingScreenProps> = () => {
  const classes = useStyles();

  return (
    <Layout>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.fullHeight}
      >
        <Grid item>
          <LoadingSpinner />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default LoadingScreen;
