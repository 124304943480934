import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../stores/index';
import { ColorBox } from '../ColorBox/ColorBox';
import { CommonModal } from '../Modals/CommonModal';

interface ExpiryTimerProps {
  onExpire?: () => void;
}

export const ExpiryTimer: FC<ExpiryTimerProps> = observer(({ onExpire }) => {
  const {
    cartStore: { cart, clearCart },
  } = useStores();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [displayTime, setDisplayTime] = useState('');
  const screenReaderAlertTimeout = null as any;
  let interval = null as any;

  const getDiff = () => {
    const end = moment(cart?.expiresAt);
    return moment.duration(end.diff(new Date())).asSeconds();
  };

  const getSeconds = () => Math.floor(getDiff() % 60);

  const getMinutes = () => Math.floor(getDiff() / 60);

  const isLastMinute = () => getMinutes() < 1 && getSeconds() <= 59;

  const getDisplayTime = () => {
    const seconds = getSeconds();
    const minutes = getMinutes();

    let fixedMinutes = minutes as any;
    let fixedSeconds = seconds as any;

    if (!isLastMinute()) {
      fixedMinutes = seconds <= 30 ? minutes : minutes + 1;
      fixedSeconds = seconds <= 30 && seconds !== 0 ? '30' : '00';
    }

    if (isLastMinute()) {
      const stringSeconds = String(seconds);
      if (stringSeconds.length < 2) fixedSeconds = '00';
      if (stringSeconds.length === 2)
        fixedSeconds = `${stringSeconds.charAt(0)}0`;
      fixedMinutes = minutes;
    }

    return `${fixedMinutes}:${fixedSeconds}`;
  };

  const updateTimer = async () => {
    const clockUpdateInterval = isLastMinute() ? 10 : 30;
    const seconds = getSeconds();
    const minutes = getMinutes();

    if (seconds % clockUpdateInterval === 0) {
      setDisplayTime(getDisplayTime());
    }

    // Warn user about cart expiring
    if (isLastMinute() && seconds === 59) {
      setModalOpen(true);
    }

    if (minutes <= 0 && seconds <= 0) {
      try {
        await clearCart(true);
      } finally {
        clearTimeout(screenReaderAlertTimeout);
        onExpire?.();
      }
    }

    return () => {
      clearTimeout(screenReaderAlertTimeout);
    };
  };

  const initTimer = () => {
    updateTimer();
    interval = setInterval(() => {
      updateTimer();
    }, 1000);
  };

  useEffect(() => {
    if (!cart?.expiresAt) {
      return;
    }
    initTimer();
    if (!displayTime) {
      setDisplayTime(getDisplayTime());
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [cart, displayTime]);

  const onModalConfirm = () => {
    setModalOpen(false);
  };

  if (!cart?.expiresAt) {
    return null;
  }

  return (
    <>
      <Box component="section">
        {displayTime && (
          <Box>
            <ColorBox
              color="red"
              m={3}
              mb={0}
              p={1}
              aria-hidden="true"
              display="flex"
              alignItems="center"
              whiteText
            >
              <FontAwesomeIcon icon={faExclamationTriangle} />
              <Box ml={1}>
                {t('orders.add.reservationExpires', { time: displayTime })}
              </Box>
            </ColorBox>
          </Box>
        )}
      </Box>

      <CommonModal
        title={t('orders.add.expirationWarning.title')}
        onConfirm={onModalConfirm}
        confirmText="common.close"
        type="prompt"
        open={modalOpen}
      >
        <Typography>{t('orders.add.expirationWarning.body')}</Typography>
      </CommonModal>
    </>
  );
});
