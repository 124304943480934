import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStores } from '../../stores/index';

interface MainSpinnerProps {}

const useStyles = makeStyles({
  spinnerContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const MainSpinner: React.FC<MainSpinnerProps> = observer(() => {
  const [visible, setVisible] = useState(false);
  const {
    authStore: { isLoading: authStoreLoading },
    eventStore: { isLoading: eventStoreLoading },
    userStore: { isLoading: userStoreLoading },
    skyboxStore: { isLoading: skyboxStoreLoading },
    settingsStore: { isLoading: settingsStoreLoading },
    reportStore: { isLoading: reportStoreLoading },
    ownerStore: { isLoading: ownerStoreLoading },
  } = useStores();

  const isSomeStoreLoading =
    eventStoreLoading ||
    authStoreLoading ||
    userStoreLoading ||
    skyboxStoreLoading ||
    settingsStoreLoading ||
    reportStoreLoading ||
    ownerStoreLoading;

  const classes = useStyles();

  useEffect(() => {
    if (isSomeStoreLoading) {
      setTimeout(() => {
        setVisible(true);
      }, 250);
    } else {
      setVisible(false);
    }
  }, [visible, setVisible, isSomeStoreLoading]);

  if (!isSomeStoreLoading || !visible) {
    return null;
  }

  return (
    <Box>
      {/* Blocks user interaction while the spinner is visible */}
      <Box className={classes.spinnerContainer}>
        <CircularProgress color="secondary" />
      </Box>
    </Box>
  );
});

export default MainSpinner;
