import axios, { AxiosError } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import { PATHS } from '../constants/routes';
import CartService from '../services/CartService';
import UserService from '../services/UserService';
import { IPersonalizationFields, StoreState } from '../types/Common';
import { PersonalizationType } from '../types/Event';
import { ICart, ICheckoutResponse, ReserveTicketObject } from '../types/Order';
import { IEndUser } from '../types/User';
import { getCartSum } from '../utils/cartUtils';
import { storeAutoSave } from '../utils/commonUtils';
import { ErrorCode } from '../utils/error';
import AuthStore from './AuthStore';
import EventStore from './EventStore';
import ToastStore from './ToastStore';

interface SetEventIdsDTO {
  eventSeriesId?: number;
  eventId?: number;
}

export interface GetReportDTO {
  skip?: number;
  take?: number;
}

export interface AddServingsToCartDTO {
  productId: string;
  sitting: string;
  amount: number;
}

const INITIAL_EVENT_IDS = {
  eventId: undefined,
  eventSeriesId: undefined,
};

const getRedirectUrls = () => {
  const successRedirectPath = AuthStore.isOwner
    ? PATHS.orders.add.orderCompleted
    : PATHS.orders.main;
  const failRedirectPath = PATHS.orders.add.paymentFailure;

  let host = `${window.location.protocol}//${window.location.host}`;

  // RedirectURL must be an url and localhost:3001 is not:
  if (window.location.host === 'localhost:3001') {
    host = 'https://google.com';
  }

  const redirectUrl = `${host}${successRedirectPath}`;
  const failRedirectUrl = `${host}${failRedirectPath}`;

  return { redirectUrl, failRedirectUrl };
};

class CartModel {
  savedCartId?: string = undefined;

  formValues: any = {};

  state: StoreState = 'Idle';

  cart?: ICart = undefined;

  personalizationAdded: boolean = false;

  personalizationFields?: IPersonalizationFields = undefined;

  personalizationData?: any = undefined;

  skyboxId?: string = undefined;

  eventIds: { eventId?: number; eventSeriesId?: number } = INITIAL_EVENT_IDS;

  activeStep: number = 0;

  checkoutResponse?: ICheckoutResponse = undefined;

  contactDetails?: {
    contactName?: string;
    contactPhone?: string;
    identifierText?: string;
    contactEmail?: string;
  } = undefined;

  isChildOrder: boolean = false;

  isPaymentInitialized: boolean = false;

  constructor() {
    makeAutoObservable(this);

    const omittedFields = [
      'isChildOrder',
      'skyboxInvites',
      'order',
      'calendarReservations',
      'isPaymentInitialized',
      'personalizationData',
      'cart',
      'checkoutResponse',
      'state',
      'uiState',
    ];
    storeAutoSave(this, 'cartStore', omittedFields);
  }

  get cartId() {
    return this.cart?.id;
  }

  get sum() {
    if (!this.cart) return 0;
    return getCartSum(this.cart);
  }

  clearCartFromApi = async (cartId: string) => {
    this.state = 'Loading';
    try {
      await CartService.clearCart({
        cartId,
      });
      this.state = 'Success';
    } catch (e) {
      this.state = 'Error';
      throw e;
    }
  };

  /**
   * Reserves a ticket for selected skybox
   */
  reserveTicketsForSkybox = async (tickets: ReserveTicketObject) => {
    const { eventSeriesId, eventId } = this.eventIds;
    if (!eventSeriesId || !this.cart || !eventId || !this.skyboxId) {
      console.error('Missing some parameters, mate.');
      return;
    }

    runInAction(() => {
      this.state = 'Loading';
    });
    try {
      const results = await CartService.reserveSkyboxTickets({
        cartId: this.cart.id,
        eventId,
        eventSeriesId,
        skyboxId: this.skyboxId,
        tickets: [tickets],
      });

      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess(
          'successes.order.reserveTicketsForSkyboxSuccess',
        );
        this.cart = results.data;

        const { data } = results;
        // Saving the order personalization fields
        if (data?.orderPersonalization) {
          this.personalizationFields = data?.orderPersonalization;
        }

        // Saving the ticket personalization fields
        if (
          data?.tickets[0]?.ticketPersonalization &&
          Object.keys(data?.tickets[0].ticketPersonalization).length
        ) {
          this.personalizationFields = data?.tickets[0].ticketPersonalization;
        }
      });
    } catch (error) {
      let msg = 'errors.order.reserveTicketsForSkyboxFailed';
      this.state = 'Error';
      if (axios.isAxiosError(error)) {
        if (error?.response?.data?.response?.Error) {
          const { code, subcode } = error.response.data.response.Error;
          if (code === 6 && subcode === 1001)
            msg = 'errors.cart.tooFewAdjacentSeats';
          if (code === 6 && subcode === 11790)
            msg = 'errors.cart.moreTicketsSoldThanLimit';
          if (code === 6 && subcode === 1002) msg = 'errors.cart.tooFewSeats';
        }
      }
      ToastStore.showError(msg);

      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Creates a new cart
   */
  createCart = async (parentId?: string) => {
    if (!this.eventIds.eventId) {
      console.error('Missing some parameters, mate.');
      return;
    }
    runInAction(() => {
      this.state = 'Loading';
    });

    try {
      const results = await CartService.createCart({
        eventId: this.eventIds.eventId,
        parentId,
      });
      runInAction(() => {
        this.contactDetails = undefined;
        this.isChildOrder = !!parentId;
        this.state = 'Success';
        this.cart = results.data;
        this.savedCartId = results.data.id;
      });
    } catch (error) {
      ToastStore.showError('errors.order.createCartFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   *  Fetches cart
   */
  getCart = async () => {
    if (!this.savedCartId) {
      console.error('Missing cartId, mate.');
      return;
    }
    runInAction(() => {
      this.state = 'Loading';
    });
    try {
      const results = await CartService.getCartById(this.savedCartId);
      runInAction(() => {
        this.state = 'Success';

        if (!results.data) {
          return this.clearCart();
        }

        this.cart = results.data;
      });
    } catch (error) {
      // getCart failed, probably doesn't matter
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  setActiveStep = (step: number) => {
    this.activeStep = step;
  };

  setEventIds = (eventIds: SetEventIdsDTO) => {
    this.eventIds = eventIds;
  };

  resetEventIds = () => this.setEventIds(INITIAL_EVENT_IDS);

  setSkyboxId = (skyboxId?: string) => {
    this.skyboxId = skyboxId;
  };

  setContactDetails = (contactDetails?: any) => {
    this.contactDetails = contactDetails;
  };

  clearCart = async (showExpiryMessage?: boolean) => {
    if (this.savedCartId) {
      await this.clearCartFromApi(this.savedCartId);
    }
    this.skyboxId = undefined;
    this.cart = undefined;
    this.savedCartId = undefined;
    this.contactDetails = undefined;
    if (showExpiryMessage) {
      ToastStore.showError('errors.order.ticketsExpired');
    }
  };

  get isLoading() {
    return this.state === 'Loading';
  }

  /**
   * Adds ticket / order personalization or order comment
   */
  addPersonalization = async (postData: any, type: PersonalizationType) => {
    const data = {
      ...postData,
      ...{
        cartId: this.cartId,
        eventId: EventStore.event?.id,
      },
    };
    let apiCall = null;

    if (type === 'TicketPersonalization') {
      apiCall = CartService.addTicketPersonalization;
    } else if (type === 'OrderPersonalization') {
      apiCall = CartService.addOrderPersonalization;
    } else if (type === 'OrderComment') {
      apiCall = CartService.addOrderComment;
    }

    if (!data || !apiCall || !this.cartId) {
      ToastStore.showError('errors.cart.personalizationFailed');
      return null;
    }

    try {
      const response = await apiCall(data);
      const p = response.data.PersonalizationResponse;
      if (p && p.Personalization) {
        try {
          if (p.Personalization.find((obj: any) => obj.SubCode === 12365))
            throw new Error(
              'errors.cart.personalizationFailedTicketsPerVisitor',
            );
          if (p.Personalization.find((obj: any) => obj.SubCode === 12350))
            throw new Error('errors.cart.personalizationFailedMandatoryFields');
        } catch (e: any) {
          ToastStore.showError(e.message);
          this.state = 'Error';
          throw e;
        }
      }
      this.state = 'Success';
      this.personalizationData = data;
      this.personalizationAdded = true;
      return response;
    } catch (e: any) {
      this.state = 'Error';
      ToastStore.showError('errors.cart.personalizationFailed');
      throw e;
    }
  };

  /**
   * Adds skybox to cart
   */
  addSkyboxToCart = async (parentId?: string) => {
    if (!this.eventIds.eventId || !this.skyboxId) {
      console.error('Missing some parameters, mate.');
      return;
    }

    this.isChildOrder = !!parentId;
    runInAction(() => {
      this.state = 'Loading';
    });

    try {
      const createCartResult = await CartService.createCart({
        eventId: this.eventIds.eventId,
        parentId,
      });
      const cartId = createCartResult.data.id;
      this.savedCartId = cartId;
      const addResult = await CartService.addSkyboxToCart({
        cartId,
        eventId: this.eventIds.eventId,
        skyboxId: this.skyboxId,
      });
      runInAction(() => {
        this.state = 'Success';
        this.cart = addResult.data;
        ToastStore.showSuccess('successes.order.addSkyboxToCartSuccess');
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      if (axios.isAxiosError(error)) {
        if (error.response?.data.code === ErrorCode.SKYBOX_ALREADY_IN_CART) {
          ToastStore.showError('errors.order.skyboxAlreadyInCart');
        } else {
          ToastStore.showError('errors.order.addSkyboxToCartFailed');
        }
      }
      throw error;
    }
  };

  /**
   * Claims the shopping cart for existing user
   */
  claimShoppingCartForUser = async (userId: string) => {
    if (!this.cart) {
      console.error('Missing some parameters, mate.');
      return;
    }
    runInAction(() => {
      this.state = 'Loading';
    });
    try {
      await CartService.claimShoppingCartForUser({
        cartId: this.cart.id,
        userId,
      });
      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess(
          'successes.order.claimShoppingCartForUserSuccess',
        );
        //this.cart = results.data;
      });
    } catch (error) {
      ToastStore.showError('errors.order.claimShoppingCartForUserFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Creates a new user and claims a cart for her
   */
  createUserAndClaimCart = async (user: IEndUser) => {
    if (!this.cart) {
      console.error('Missing some parameters, mate.');
      return;
    }
    runInAction(() => {
      this.state = 'Loading';
    });
    try {
      const userResults = await UserService.createEndUser(user);
      await CartService.claimShoppingCartForUser({
        cartId: this.cart.id,
        userId: userResults.data.id,
      });
      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.order.createUserAndClaimCartSuccess');
      });
    } catch (error) {
      ToastStore.showError(
        [ErrorCode.DUPLICATE_ENTRY, ErrorCode.USER_EXISTS].includes(
          (error as AxiosError).response?.data?.code,
        )
          ? 'errors.user.createFailedBecauseExists'
          : 'errors.order.createUserAndClaimCartFailed',
      );
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Payment
   */
  makePayment = async () => {
    if (!this.cartId) {
      console.error('Cannot make payment: No event selected');
      return;
    }
    this.checkoutResponse = undefined;
    runInAction(() => {
      this.state = 'Loading';
    });

    try {
      const response = await CartService.makePayment({
        cartId: this.cartId,
        redirectUrl: getRedirectUrls().redirectUrl,
        failRedirectUrl: getRedirectUrls().failRedirectUrl,
        contactDetails: this.contactDetails,
      });
      this.state = 'Success';

      if (response.data?.paidOn) {
        ToastStore.showSuccess('successes.order.orderConfirmed');
        return response;
      }

      this.isPaymentInitialized = true;
      this.checkoutResponse = response.data;
    } catch (e) {
      this.checkoutResponse = undefined;
      this.isPaymentInitialized = false;
      ToastStore.showError('errors.order.makePayment');
      this.state = 'Error';
      throw e;
    }
  };

  /**
   * Creates a new cart
   */
  payByInvoice = async (reference?: string) => {
    if (!this.cartId) {
      console.error('Missing some parameters, mate.');
      return;
    }

    runInAction(() => {
      this.state = 'Loading';
    });
    try {
      await CartService.payByInvoice({
        cartId: this.cartId,
        redirectUrl: getRedirectUrls().redirectUrl,
        failRedirectUrl: getRedirectUrls().failRedirectUrl,
        contactDetails: this.contactDetails,
        reference,
      });
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.order.payByInvoice');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };
}

const CartStore = new CartModel();

export default CartStore;
