export const getUserDisplayName = (
  userInfo:
    | {
        email: string;
        firstName?: string;
        lastName?: string;
      }
    | undefined,
) => {
  if (!userInfo) {
    return 'MISSING_NAME';
  }

  const { firstName, lastName, email } = userInfo;
  return firstName && lastName
    ? `${firstName} ${lastName}`
    : firstName || lastName || email;
};
