import { makeAutoObservable, runInAction } from 'mobx';
import AssetService from '../services/AssetService';
import OrderService from '../services/OrderService';
import OwnerService from '../services/OwnerService';
import SkyboxService from '../services/SkyboxService';
import { findById } from '../utils';
import AuthStore from './AuthStore';
import CartStore from './CartStore';
import ToastStore from './ToastStore';
// @ts-ignore
import FileSaver from 'file-saver';
import { GetQueryParams, StoreState } from '../types/Common';
import {
  CreateSkyboxInviteDTO,
  GetOrdersDTO,
  GetOwnerOrdersDTO,
  ReserveMySkyboxDTO,
} from '../types/Order';
import { IOwner } from '../types/Owner';
import {
  FreeMySkyboxDTO,
  IEventSkybox,
  IEventSkyboxSettings,
  ISkybox,
  ISkyboxInvite,
  ISkyboxOrder,
  ISkyboxWithEventSkybox,
  PlaceForTargetedRentalDTO,
  RemoveAssetFromSkyboxDTO,
  ResetMySkyboxDTO,
  SetOwnerForEventDTO,
  UpdateEventSeriesSkyboxSettingsDTO,
  UpdateEventSkyboxSettingsDTO,
} from '../types/Skybox';

class SkyboxModel {
  state: StoreState = 'Idle';
  ownerList?: IOwner[] = undefined;
  skyboxList?: ISkybox[] = undefined;
  skyboxInvites?: ISkyboxInvite[] = undefined;
  activeSkyboxes?: ISkybox[] = undefined;
  mySkyboxes?: ISkybox[] = undefined;
  myActiveSkyboxes?: ISkybox[] = undefined;
  mySkyboxesOrders?: any = undefined;
  myEventSkyboxes?: ISkyboxWithEventSkybox[] = undefined;
  myEventSeriesSkyboxes?: IEventSkybox[] = undefined;
  skyboxListPage?: ISkybox[] = undefined;
  skybox?: ISkybox = undefined;
  eventSkyboxes?: ISkybox[] = undefined;
  eventSeriesSkyboxes?: ISkybox[] = undefined;
  eventSkyboxSettings?: IEventSkyboxSettings = undefined;
  eventSeriesSkyboxSettings?: IEventSkyboxSettings = undefined;
  skyboxOrders?: any = undefined;
  orderCount?: number = 0;
  myOrderCount?: number = 0;
  // Updates whenever owner frees/reserves/resets his skybox settings
  mySkyboxesUpdateIndex: number = 0;
  skyboxOrder?: ISkyboxOrder = undefined;
  skyboxTotal: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  getSkyboxList = async (params?: GetQueryParams) => {
    this.state = 'Loading';
    try {
      const { data: ownerList }: { data: IOwner[] } =
        await OwnerService.getOwnerList();
      const {
        data: { results, total },
      } = await SkyboxService.getSkyboxList(params);
      runInAction(() => {
        this.skyboxListPage = results.map((skybox: ISkybox) => {
          const owner = findById(ownerList, skybox.ownerCompanyId);
          const ownerName = owner ? owner.name : '-';
          return {
            ...skybox,
            ownerName,
          };
        });
        this.skyboxTotal = total;
        this.ownerList = ownerList;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
        ToastStore.showError('errors.skybox.getSkyboxesFailed');
      });
      throw error;
    }
  };

  getActiveSkyboxes = async () => {
    this.state = 'Loading';
    try {
      const response = await SkyboxService.getActiveSkyboxes();
      runInAction(() => {
        this.activeSkyboxes = response.data?.results;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
        ToastStore.showError('errors.skybox.getSkyboxesFailed');
      });
      throw error;
    }
  };

  getOwnerList = async () => {
    this.state = 'Loading';
    try {
      const { data: ownerList }: { data: IOwner[] } =
        await OwnerService.getOwnerList();
      runInAction(() => {
        this.ownerList = ownerList;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
        ToastStore.showError('errors.skybox.getOwnersFailed');
      });
      throw error;
    }
  };

  /**
   * Load a skybox by id
   */
  getSkybox = async (id: string) => {
    this.state = 'Loading';
    let ownerList = undefined as IOwner[] | undefined;
    try {
      if (AuthStore.isAdmin) {
        const response = await OwnerService.getOwnerList();
        ownerList = response.data;
      }
      const skybox = await SkyboxService.getSkybox(id);
      runInAction(() => {
        if (AuthStore.isAdmin) this.ownerList = ownerList;
        this.skybox = skybox;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.skybox.getSkyboxFailed');
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Create new skybox
   */
  createSkybox = async (skybox: ISkybox, cb: Function) => {
    this.state = 'Loading';
    try {
      const data = {
        ...skybox,
      };
      if (skybox.newFeaturedAsset) {
        const name = `${skybox.id}_${skybox.newFeaturedAsset.name}`;
        const content = skybox.newFeaturedAsset;
        const { data: featuredAsset } = await AssetService.createAsset(
          name,
          content,
        );
        data.featuredAsset = featuredAsset?.results || null;
        data.newFeaturedAsset = undefined;
      }
      if (skybox.newAsset) {
        const name = `${skybox.id}_${skybox.newAsset.name}`;
        const content = skybox.newAsset;
        const { data: asset } = await AssetService.createAsset(name, content);
        if (asset?.results) {
          if (Array.isArray(data.assets)) {
            data.assets.push(asset.results);
          } else {
            data.assets = [asset.results];
          }
        }
        data.newAsset = undefined;
      }
      if (skybox.newLogoAsset) {
        const name = `${skybox.id}_${skybox.newLogoAsset.name}`;
        const content = skybox.newLogoAsset;
        const { data: logoAsset } = await AssetService.createAsset(
          name,
          content,
        );
        data.logoAsset = logoAsset?.results || null;
        data.newLogoAsset = undefined;
      }
      await SkyboxService.createSkybox(data);
      ToastStore.showSuccess('successes.skybox.createSuccess');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error: any) {
      if (cb) cb(error);
      let message = 'errors.skybox.createFailed';
      if (error.response.data.duplicateMapId) {
        message = 'errors.skybox.duplicateMapId';
      }
      ToastStore.showError(message);
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Add an (image) asset
   */
  addAsset = async ({ image }: any, skyboxId: string) => {
    this.state = 'Loading';
    try {
      const {
        data: {
          results: { id },
        },
      } = await AssetService.createAsset(image.name, image);
      await SkyboxService.addAssetToSkybox({
        assetId: id,
        skyboxId,
      });
      await SkyboxService.getSkybox(skyboxId);
      runInAction(async () => {
        ToastStore.showSuccess('successes.skybox.addAssetSuccess');
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.addAssetFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Remove an asset with ID
   */
  removeAsset = async ({ assetId, skyboxId }: RemoveAssetFromSkyboxDTO) => {
    this.state = 'Loading';
    try {
      await SkyboxService.removeAssetFromSkybox({ assetId, skyboxId });
      await SkyboxService.getSkybox(skyboxId);
      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.skybox.removeAssetSuccess');
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.removeAssetFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Update skybox
   */
  updateSkybox = async (skybox: ISkybox, cb: Function) => {
    this.state = 'Loading';

    try {
      const data = {
        ...skybox,
      };
      if (skybox.newFeaturedAsset) {
        const name = `${skybox.id}_${skybox.newFeaturedAsset.name}`;
        const content = skybox.newFeaturedAsset;
        const { data: featuredAsset } = await AssetService.createAsset(
          name,
          content,
        );
        data.featuredAsset = featuredAsset?.results || null;
        data.newFeaturedAsset = undefined;
      }
      if (skybox.newLogoAsset) {
        const name = `${skybox.id}_${skybox.newLogoAsset.name}`;
        const content = skybox.newLogoAsset;
        const { data: logoAsset } = await AssetService.createAsset(
          name,
          content,
        );
        data.logoAsset = logoAsset?.results || null;
        data.newLogoAsset = undefined;
      }
      // @ts-ignore
      delete data.assets;

      if (isNaN(data.defaultPrice)) {
        // @ts-ignore
        data.defaultPrice = undefined;
      }
      await SkyboxService.updateSkybox(data);
      ToastStore.showSuccess('successes.skybox.updateSuccess');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error: any) {
      if (cb) cb(error);
      let message = 'errors.skybox.updateFailed';
      if (error.response.data.duplicateMapId) {
        message = 'errors.skybox.duplicateMapId';
      }
      ToastStore.showError(message);
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  deactivateSkybox = async (id: string) => {
    this.state = 'Loading';
    try {
      await SkyboxService.deactivateSkybox(id);
      ToastStore.showSuccess('successes.skybox.deactivateSuccess');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.deactivateFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  getEventSkyboxes = async (eventId: string | number) => {
    this.state = 'Loading';
    try {
      const results = await SkyboxService.getEventSkyboxes(String(eventId));
      runInAction(() => {
        this.eventSkyboxes = results.data;
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.getSkyboxesFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Get available skyboxes by eventSeriesId
   */
  getEventSeriesSkyboxes = async (eventSeriesId: string | number) => {
    this.state = 'Loading';
    try {
      const results = await SkyboxService.getEventSeriesSkyboxes(
        String(eventSeriesId),
      );
      runInAction(() => {
        this.eventSeriesSkyboxes = results.data;
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.getSkyboxesFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Fetches event related skybox settings
   */
  getEventSkyboxSettings = async (eventId: string) => {
    this.state = 'Loading';
    try {
      const results = await SkyboxService.getEventSkyboxSettings(eventId);
      runInAction(() => {
        this.eventSkyboxSettings = results.data;
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.getSettingsFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Fetches event series related skybox settings
   */
  getEventSeriesSkyboxSettings = async (eventSeriesId: string) => {
    this.state = 'Loading';
    try {
      const results =
        await SkyboxService.getEventSeriesSkyboxSettings(eventSeriesId);
      runInAction(() => {
        this.eventSeriesSkyboxSettings = results.data;
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.getSettingsFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Update Skybox settings related to certain event
   */
  updateEventSkyboxSettings = async (
    eventId: string,
    data: UpdateEventSkyboxSettingsDTO,
  ) => {
    this.state = 'Loading';
    try {
      const results = await SkyboxService.updateEventSkyboxSettings(
        eventId,
        data,
      );
      runInAction(() => {
        this.state = 'Success';
        if (results.data.results === 'ok') {
          ToastStore.showSuccess('successes.common.save');
          this.getEventSkyboxSettings(eventId);
          this.getEventSkyboxes(eventId);
        }
      });
      return results.data;
    } catch (error) {
      ToastStore.showError('errors.common.save');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Update Skybox settings related to certain event series
   */
  updateEventSeriesSkyboxSettings = async (
    eventSeriesId: string,
    data: UpdateEventSeriesSkyboxSettingsDTO,
  ) => {
    this.state = 'Loading';
    try {
      const results = await SkyboxService.updateEventSeriesSkyboxSettings(
        eventSeriesId,
        data,
      );
      runInAction(() => {
        this.state = 'Success';
        if (results.data.results === 'ok') {
          ToastStore.showSuccess('successes.common.save');
          this.getEventSeriesSkyboxSettings(eventSeriesId);
          this.getEventSeriesSkyboxes(eventSeriesId);
        }
      });
      return results.data;
    } catch (error) {
      ToastStore.showError('errors.common.save');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Fetches current (owner) user's own skyboxes
   */
  getMySkyboxes = async (params?: GetQueryParams) => {
    this.state = 'Loading';
    try {
      const response = await SkyboxService.getMySkyboxes(params);
      runInAction(() => {
        this.state = 'Success';
        this.mySkyboxes = response.data?.results;
        this.skyboxTotal = response.data?.total;
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.getMySkyboxesFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  getMyActiveSkyboxes = async (params?: GetQueryParams) => {
    this.state = 'Loading';
    try {
      const response = await SkyboxService.getMyActiveSkyboxes(params);
      runInAction(() => {
        this.state = 'Success';
        this.myActiveSkyboxes = response.data?.results;
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.getMySkyboxesFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Fetches current (owner) user's own skyboxes related to an event
   */
  getMyEventSkyboxes = async (eventId: string | number) => {
    this.state = 'Loading';
    try {
      const results = await SkyboxService.getMyEventSkyboxes(eventId as string);
      runInAction(() => {
        this.state = 'Success';
        this.myEventSkyboxes = results.data;
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.getMySkyboxesFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Reserves (owner's) own skyboxc
   */
  reserveMySkybox = async (data: ReserveMySkyboxDTO) => {
    this.state = 'Loading';
    try {
      await SkyboxService.reserveMySkybox(data);
      await this.getMyEventSkyboxes(String(data.eventId));

      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.skybox.reserveMySkybox');
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.reserveMySkybox');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  freeMySkybox = async (data: FreeMySkyboxDTO) => {
    this.state = 'Loading';
    try {
      await SkyboxService.freeMySkybox(data);
      await this.getMyEventSkyboxes(String(data.eventId));

      runInAction(() => {
        this.state = 'Success';

        ToastStore.showSuccess('successes.skybox.freeMySkybox');
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.freeMySkybox');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  resetMySkybox = async (data: ResetMySkyboxDTO) => {
    this.state = 'Loading';
    try {
      await SkyboxService.resetMySkybox(data);
      await this.getMyEventSkyboxes(String(data.eventId));

      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.skybox.resetMySkybox');
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.resetMySkybox');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Fetches Orders made for owner's own skyboxes
   */
  getMySkyboxesOrders = async (params: GetOwnerOrdersDTO) => {
    this.state = 'Loading';
    try {
      const results = await SkyboxService.getMySkyboxesOrders(params);

      runInAction(() => {
        this.state = 'Success';
        this.mySkyboxesOrders = results.data[0];
        this.myOrderCount = results.data[1];
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.getSkyboxOrders');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  getSkyboxOrders = async (params: GetOrdersDTO) => {
    this.state = 'Loading';
    try {
      const results = await OrderService.getSkyboxOrders(params as any);

      runInAction(() => {
        this.state = 'Success';
        this.skyboxOrders = results.data[0];
        this.orderCount = results.data[1];
      });
      return results.data;
    } catch (error) {
      ToastStore.showError('errors.skybox.getSkyboxOrders');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  getSkyboxOrder = async (purchaseId: string) => {
    this.state = 'Loading';
    try {
      const results = await OrderService.getSkyboxOrder(purchaseId);

      runInAction(() => {
        this.state = 'Success';
        this.skyboxOrder = results.data;
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.getSkyboxOrder');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  getMySkyboxOrder = async (purchaseId: string) => {
    this.state = 'Loading';
    try {
      const results = await OrderService.getMySkyboxOrder(purchaseId);

      runInAction(() => {
        this.state = 'Success';
        this.skyboxOrder = results.data;
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.getSkyboxOrder');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  getSkyboxInvites = async (orderId?: string) => {
    const purchaseId = orderId || CartStore.cartId;

    if (!purchaseId) {
      console.error('Cannot get invites: No cart available');
      return;
    }

    if (!AuthStore.isLoggedIn) return;

    this.state = 'Loading';
    try {
      const results = await SkyboxService.getSkyboxInvites(purchaseId);

      runInAction(() => {
        this.state = 'Success';
        this.skyboxInvites = results.data;
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.getSkyboxInvites');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Creates a skybox invite
   */
  createSkyboxInvite = async (data: CreateSkyboxInviteDTO) => {
    this.state = 'Loading';
    try {
      await SkyboxService.createSkyboxInvite(data);
      runInAction(() => {
        this.getSkyboxInvites(data.purchaseId);
        this.state = 'Success';
        ToastStore.showSuccess('successes.order.createSkyboxInviteSuccess');
      });
    } catch (error) {
      ToastStore.showError('errors.order.createSkyboxInviteFailed');
      runInAction(() => {
        this.state = 'Error';
      });
    }
  };

  /**
   * Download skybox ticket
   */
  downloadSkyboxTicket = async (
    code: string,
    purchaseId: string,
    ticketIndex: number,
  ) => {
    if (!CartStore.cartId && !purchaseId) {
      console.error('Cannot download a ticket: Cart/purchase not available');
      return;
    }
    this.state = 'Loading';

    try {
      const purchaseRes = await OrderService.getOrder(purchaseId!);
      const purchase = purchaseRes.data.result;
      const response = await OrderService.downloadSkyboxTicket({
        purchaseId: (purchaseId || CartStore.cartId)!,
        code,
      });

      const blob = new Blob([response.data], {
        type: 'application/pdf;charset=utf-8',
      });
      FileSaver.saveAs(
        blob,
        `ticket-${purchase.livexOrderNumber}-${ticketIndex + 1}.pdf`,
      );
      this.state = 'Success';
    } catch (error) {
      ToastStore.showError('errors.order.downloadSkyboxTicket');
      this.state = 'Error';
      throw error;
    }
  };

  placeForTargetedRental = async (data: PlaceForTargetedRentalDTO) => {
    this.state = 'Loading';
    try {
      await SkyboxService.placeForTargetedRental(data);
      await this.getMyEventSkyboxes(String(data.eventId));

      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.skybox.placeForTargetedRental');
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.placeForTargetedRental');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  setOwnerForEvent = async (skyboxId: string, data: SetOwnerForEventDTO) => {
    this.state = 'Loading';
    try {
      await SkyboxService.setOwnerForEvent(skyboxId, data);

      if (AuthStore.isOwner) {
        await this.getMyEventSkyboxes(String(data.eventId));
      }
      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.skybox.setOwnerForEvent');
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.setOwnerForEvent');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  reset = () => {
    this.skyboxOrder = undefined;
  };
}

const SkyboxStore = new SkyboxModel();

export default SkyboxStore;
