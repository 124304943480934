import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { OrderLayout, showChildOrderByParams } from '../../components/Layout';
import { TicketDeliveryTable } from '../../components/TicketDeliveryTable/TicketDeliveryTable';
import { useStores } from '../../stores/index';
import { PurchaseState } from '../../types/Order';

export const OrderSendTicketsScreen: React.FC = observer(() => {
  const history = useHistory();

  const {
    skyboxStore: { skyboxOrder },
    orderStore: { order },
  } = useStores();

  const { livexOrderNumber }: { livexOrderNumber: string } = useParams();
  const showChildOrder = showChildOrderByParams(history.location.search);

  const currentOrder = useMemo(() => {
    if (showChildOrder) {
      return order;
    }
    return skyboxOrder?.purchase;
  }, [order, showChildOrder, skyboxOrder]);

  const id = useMemo(() => {
    if (showChildOrder) {
      return order?.id;
    }
    return skyboxOrder?.purchase?.id;
  }, [order, showChildOrder, skyboxOrder]);

  return (
    <OrderLayout
      livexOrderNumber={livexOrderNumber}
      showChildOrder={showChildOrder}
      tabs={['details', 'sendTickets']}
    >
      <Box>
        <Box p={2}>
          {currentOrder?.state === PurchaseState.FAILED && (
            <Box mb={3}>
              <Alert severity={'error'}>
                {t('orders.detailsModal.processingFailed')}
              </Alert>
            </Box>
          )}
          {id &&
            currentOrder?.tickets?.length &&
            currentOrder.ticketFilename && (
              <TicketDeliveryTable
                tickets={currentOrder.tickets}
                purchaseId={id}
              />
            )}
        </Box>
      </Box>
    </OrderLayout>
  );
});
