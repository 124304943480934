import { makeAutoObservable, runInAction } from 'mobx';
import { api, getPath } from '../../services/api';
import ToastStore from '../../stores/ToastStore';

const PATH = `/customer/{customerId}/skybox-events/list`;

interface ListSkyboxEventSeriesEventsRequestDto {
  page: number;
  pageSize: number;
  search?: string;
  eventSeriesId?: number;
  includeOwnSkyboxInfo?: boolean;
}

export interface ListSkyboxEventSeriesEventsResponseItemDto {
  id: number;
  eventSeriesId?: number;
  title: string;
  date?: Date;
  ownSkyboxInfo?: {
    vacantCount: number;
    reservedCount: number;
  };
}

export type ListSkyboxEventSeriesEventsResponseDto =
  ListSkyboxEventSeriesEventsResponseItemDto[];

export class SkyboxEventListViewModel {
  private eventSeriesId?: number;
  private isOwner?: boolean;
  public events: ListSkyboxEventSeriesEventsResponseDto;
  public totalEventCount: number = 0;
  public loading: boolean = false;

  constructor(props: { eventSeriesId?: number; isOwner?: boolean }) {
    this.eventSeriesId = props.eventSeriesId;
    this.isOwner = props.isOwner;
    this.events = [];
    makeAutoObservable(this);
  }

  async fetchData(params: { search?: string; page: number; pageSize: number }) {
    runInAction(() => {
      this.loading = true;
    });
    try {
      const queryParams: ListSkyboxEventSeriesEventsRequestDto = {
        includeOwnSkyboxInfo: this.isOwner,
        eventSeriesId: this.eventSeriesId,
        ...params,
      };
      const response = await api.get(getPath(PATH), { params: queryParams });
      this.events = response.data.result;
      this.totalEventCount = response.data.total;
    } catch (e) {
      ToastStore.showError('errors.events.getAllEvents');
    } finally {
      this.loading = false;
    }
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }
}
