import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import { TFunction } from 'i18next';
import { getUserDisplayName } from '../../utils/getUserDisplayName';
import {
  dateTimeString,
  minutesToDurationString,
} from '../../utils/stringUtils';
import { Link } from '../Link';

export const CellRenderers = {
  link:
    (linkFn: (row: any) => string) =>
    ({ cell, row: { original } }: any) => (
      <Typography variant="body1">
        <Link to={linkFn(original)}>{cell.value || ''}</Link>
      </Typography>
    ),

  linkIfActive:
    (linkFn: (row: any) => string) =>
    (data: { cell: any; row: { original: any } }) => {
      return data.row.original.active
        ? CellRenderers.link(linkFn)(data)
        : data.cell.value;
    },

  linkExternal:
    (linkFn: (row: any) => string) =>
    ({ cell, row: { original } }: any) => (
      <Typography variant="body1">
        <Link external to={linkFn(original)}>
          {cell.value}
        </Link>
      </Typography>
    ),

  linkOnClick:
    (onClick: (row: any) => void) =>
    ({ cell, row: { original } }: any) => (
      <Typography variant="body1">
        <Link onClick={() => onClick(original)}>{cell.value}</Link>
      </Typography>
    ),
  linkWrapper:
    (
      linkFn: (row: any) => string,
      cellRenderer: (data: any) => JSX.Element | string,
    ) =>
    (data: any) => (
      <Link to={linkFn(data.row.original)}>{cellRenderer(data)}</Link>
    ),

  text: ({ cell }: any) => (
    <Typography variant="body1">{cell.value}</Typography>
  ),

  nameOrEmail: ({ row: { original } }: any) => (
    <Typography variant="body1">{getUserDisplayName(original)}</Typography>
  ),

  textFn:
    (fn: (cell: any, row: any) => string) =>
    ({ cell, row: { original } }: any) => (
      <Typography variant="body1">{fn(cell, original)}</Typography>
    ),

  dateTime: ({ cell }: any) => (
    <Typography variant="body1">
      {cell.value ? dateTimeString(cell.value) : ''}
    </Typography>
  ),

  boolean:
    (trueString: string, falseString: string) =>
    ({ cell }: any) => (
      <Typography variant="body1">
        {cell.value === true ? trueString : falseString}
      </Typography>
    ),

  deleteIcon:
    (deleteFn: (row: any) => any) =>
    ({ row: { original } }: any) => (
      <IconButton aria-label="delete" onClick={deleteFn(original)}>
        <Delete />
      </IconButton>
    ),

  duration:
    (t: TFunction) =>
    ({ cell }: any) => (
      <Typography>{minutesToDurationString(t)(cell.value)}</Typography>
    ),
};
