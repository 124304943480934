import { GetQueryParams } from '../types/Common';
import {
  CreateSkyboxInviteDTO,
  GetOwnerOrdersDTO,
  ReserveMySkyboxDTO,
} from '../types/Order';
import {
  AddAssetToSkyboxDTO,
  FreeMySkyboxDTO,
  ISkybox,
  ISkyboxInvite,
  PlaceForTargetedRentalDTO,
  RemoveAssetFromSkyboxDTO,
  ResetMySkyboxDTO,
  SetOwnerForEventDTO,
  UpdateEventSeriesSkyboxSettingsDTO,
  UpdateEventSkyboxSettingsDTO,
} from '../types/Skybox';
import { api, getPath } from './api';

const API_PATHS = Object.freeze({
  all: '/customer/{customerId}/skybox',
  get: '/customer/{customerId}/skybox/{itemId}',
  create: '/customer/{customerId}/skybox',
  update: '/customer/{customerId}/skybox/{itemId}',
  activate: '/customer/{customerId}/skybox/{itemId}/active',
  getAvailableSkyboxTicketTypes:
    '/customer/{customerId}/skybox-ticket/{itemId}/{secondaryItemId}/available',
  eventSkyboxes: '/customer/{customerId}/skybox/event/{itemId}',
  eventSeriesSkyboxes: '/customer/{customerId}/skybox/event-series/{itemId}',
  eventSettings: '/customer/{customerId}/skybox-settings/event/{itemId}',
  eventSeriesSettings:
    '/customer/{customerId}/skybox-settings/event-series/{itemId}',
  mySkyboxes: '/customer/{customerId}/skybox/my/',
  mySkyboxesOrders: '/customer/{customerId}/skybox/my/reservations',
  myEventSkyboxes: '/customer/{customerId}/skybox/my/event/{itemId}',
  addAsset: '/customer/{customerId}/skybox/{itemId}/addAsset/{secondaryItemId}',
  removeAsset:
    '/customer/{customerId}/skybox/{itemId}/removeAsset/{secondaryItemId}',
  reserveMySkybox: '/customer/{customerId}/skybox/reserveMySkybox',
  freeMySkybox: '/customer/{customerId}/skybox/freeMySkybox',
  targetedRental: '/customer/{customerId}/targeted-rental',
  resetMySkybox: '/customer/{customerId}/skybox/resetMySkybox',
  createInvite: '/customer/{customerId}/skybox/createInvite',
  invites: '/customer/{customerId}/skybox/invites/{itemId}',
  // cross-use
  setOwnerForEvent: '/customer/{customerId}/skybox/{itemId}/setOwnerForEvent',
  eventSkyboxesByTimePeriod: '/customer/{customerId}/skybox/allUsage',
});

export interface GetEventSkyboxesByTimePeriodDTO {
  startDate: string;
  endDate: string;
}

export interface SkyboxTicketType {
  id: number;
  text: string;
  price: number;
  priceId: number;
}

const prepareSkyboxData = (data: ISkybox) => {
  if (data.secondaryOwners?.length) {
    // @ts-ignore
    data.secondaryOwners = data.secondaryOwners.map((id) => ({ id }));
  }

  if (isNaN(data.seatCount)) data.seatCount = 0;
  if (!data.ownerDescription) data.ownerDescription = '';

  data.blockId = Number(data.blockId);
  return data;
};

const prepareSortBy = (params?: GetQueryParams) => {
  return {
    sortBy: params?.id,
    direction: params?.desc ? 'DESC' : 'ASC',
  };
};

class SkyboxServiceModel {
  getSkyboxList = async (params?: GetQueryParams) => {
    return api.get(getPath(API_PATHS.all), {
      params: { ...params, ...prepareSortBy(params) },
    });
  };

  getActiveSkyboxes = async (params?: GetQueryParams) => {
    return api.get(getPath(API_PATHS.all), {
      params: {
        ...params,
        ...prepareSortBy(params),
        active: true,
      },
    });
  };

  getSkyboxInvites = async (purchaseId: string) =>
    api.get<ISkyboxInvite[]>(getPath(API_PATHS.invites, purchaseId));

  createSkyboxInvite = async (data: CreateSkyboxInviteDTO) =>
    api.post(getPath(API_PATHS.createInvite), data);

  getSkybox = async (id: string) => {
    try {
      const response = await api.get(getPath(API_PATHS.get, id));
      if (response.data?.secondaryOwners) {
        response.data.secondaryOwners = response.data.secondaryOwners.map(
          (obj: { id: string }) => obj.id,
        );
      }
      return response.data;
    } catch (err) {
      throw err;
    }
  };
  createSkybox = async (data: ISkybox) => {
    return api.post(getPath(API_PATHS.create), prepareSkyboxData(data));
  };

  updateSkybox = async (data: ISkybox) => {
    return api.put(getPath(API_PATHS.update, data.id), prepareSkyboxData(data));
  };

  deactivateSkybox = async (id: string) =>
    api.put(getPath(API_PATHS.activate, id), { active: false });

  getEventSkyboxes = async (eventId: string) => {
    let path = getPath(API_PATHS.eventSkyboxes, eventId);
    return api.get(path);
  };

  getEventSeriesSkyboxes = async (eventId: string) =>
    api.get(getPath(API_PATHS.eventSeriesSkyboxes, eventId));

  updateEventSkyboxSettings = (
    eventId: string,
    data: UpdateEventSkyboxSettingsDTO,
  ) => api.put(getPath(API_PATHS.eventSettings, eventId), data);

  updateEventSeriesSkyboxSettings = (
    eventSeriesId: string,
    data: UpdateEventSeriesSkyboxSettingsDTO,
  ) => api.put(getPath(API_PATHS.eventSeriesSettings, eventSeriesId), data);

  getEventSkyboxSettings = (eventId: string) =>
    api.get(getPath(API_PATHS.eventSettings, eventId));

  getEventSeriesSkyboxSettings = (eventSeriesId: string) =>
    api.get(getPath(API_PATHS.eventSeriesSettings, eventSeriesId));

  getMySkyboxes = async (params?: GetQueryParams) => {
    return api.get(getPath(API_PATHS.mySkyboxes), {
      params: { ...params, ...prepareSortBy(params) },
    });
  };

  getMyActiveSkyboxes = async (params?: GetQueryParams) => {
    return api.get(getPath(API_PATHS.mySkyboxes), {
      params: {
        ...params,
        ...prepareSortBy(params),
        active: true,
      },
    });
  };

  getMySkyboxesOrders = async (params: GetOwnerOrdersDTO) =>
    api.get(getPath(API_PATHS.mySkyboxesOrders), { params });

  getMyEventSkyboxes = async (eventId: string) => {
    let path = getPath(API_PATHS.myEventSkyboxes, eventId);
    return api.get(path);
  };

  removeAssetFromSkybox = async ({
    assetId,
    skyboxId,
  }: RemoveAssetFromSkyboxDTO) =>
    api.put(getPath(API_PATHS.removeAsset, skyboxId, assetId));

  addAssetToSkybox = async ({ assetId, skyboxId }: AddAssetToSkyboxDTO) =>
    api.put(getPath(API_PATHS.addAsset, skyboxId, assetId));

  freeMySkybox = async (data: FreeMySkyboxDTO) => {
    return api.post(getPath(API_PATHS.freeMySkybox), data);
  };

  resetMySkybox = async (data: ResetMySkyboxDTO) =>
    api.post(getPath(API_PATHS.resetMySkybox), data);

  reserveMySkybox = async (data: ReserveMySkyboxDTO) =>
    api.post(getPath(API_PATHS.reserveMySkybox), data);

  getSkyboxAvailableTicketTypes = async (skyboxId: string, eventId: number) => {
    const response = await api.get<SkyboxTicketType[]>(
      getPath(API_PATHS.getAvailableSkyboxTicketTypes, skyboxId, eventId),
    );
    return response.data;
  };

  placeForTargetedRental = async (data: PlaceForTargetedRentalDTO) =>
    api.post(getPath(API_PATHS.targetedRental), data);

  /**
   * Cross-use
   */
  setOwnerForEvent = async (skyboxId: string, data: SetOwnerForEventDTO) =>
    api.post(getPath(API_PATHS.setOwnerForEvent, skyboxId), data);

  getEventSkyboxesByTimePeriod = async (
    params: GetEventSkyboxesByTimePeriodDTO,
  ) => api.get(getPath(API_PATHS.eventSkyboxesByTimePeriod), { params });
}

const SkyboxService = new SkyboxServiceModel();

export default SkyboxService;
