import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/Layout/Layout';
import { useStores } from '../../stores/index';

export const OrderFormPaymentFailureScreen: FC = observer(() => {
  const { t } = useTranslation();

  const {
    cartStore: { clearCart },
  } = useStores();

  useEffect(() => {
    clearCart();
  });

  return (
    <Layout headerText={t('orders.add.paymentFailure.title')}>
      <Box p={3}>
        <Typography>{t('orders.add.paymentFailure.body')}</Typography>
      </Box>
    </Layout>
  );
});
