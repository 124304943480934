import { createTheme } from '@material-ui/core';
import { fiFI } from '@material-ui/core/locale';
import { SkeletonClassKey } from '@material-ui/lab';
import { darken } from 'polished';
import colors from './colors';
import { getPickerOverrides } from './picker-overrides';

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiSkeleton: SkeletonClassKey;
  }
}

export const SPACING = [0, 8, 16, 24, 32, 48, 64, 128, 160, 192, 256];
export const FONT_SIZES = [
  '0.80rem',
  '0.85rem',
  '1rem',
  '1.125rem',
  '1.5rem',
  '2.25rem',
];
const BORDER_RADIUS = '2px';
const DEFAULT_ACCENT = '#202867';

export const getTheme = (accentColor?: string) => {
  const primaryAccentColor = accentColor || DEFAULT_ACCENT;
  const currentColors = {
    ...colors,
    accent: { primary: primaryAccentColor },
  };

  return createTheme(
    {
      typography: {
        fontFamily: ['Inter', 'Verdana', 'sans-serif'].join(','),
        fontSize: 14,
        body1: {
          color: currentColors.text.body1,
          fontSize: FONT_SIZES[1],
        },
        body2: {
          color: currentColors.text.body2,
          fontSize: FONT_SIZES[0],
        },
        h1: {
          fontSize: FONT_SIZES[5], // 36px
          fontWeight: 700,
          color: currentColors.accent.primary,
        },
        h2: {
          fontSize: FONT_SIZES[4], // ${SPACING[3]px}
          fontWeight: 700,
          marginBottom: SPACING[2],
          color: currentColors.accent.primary,
        },
        h3: {
          fontSize: FONT_SIZES[3], // 18px
          fontWeight: 'bold',
          color: currentColors.accent.primary,
        },
        h4: {
          fontSize: FONT_SIZES[2], // 16px
          fontWeight: 'bold',
          color: currentColors.accent.primary,
        },
        h5: {
          fontSize: FONT_SIZES[1], // 16px
          fontWeight: 'bold',
          color: currentColors.accent.primary,
        },
        button: {
          fontSize: FONT_SIZES[3],
          fontWeight: 'normal',
          textTransform: 'none',
        },
      },
      spacing: SPACING,
      palette: {
        primary: {
          main: currentColors.text.body1,
        },
        secondary: {
          main: currentColors.accent.primary,
        },
        text: {
          primary: currentColors.text.body1,
          secondary: currentColors.text.body2,
        },
        background: {
          default: currentColors.background.bg2,
        },
      },
      props: {
        MuiButton: {
          variant: 'contained',
        },
      },

      overrides: {
        MuiFormLabel: {
          root: {
            fontWeight: 600,
            fontSize: '12px',
            color: currentColors.background.bg2,
            '&$disabled, &$focused': {
              color: currentColors.text.body1,
            },
          },
          filled: {
            color: currentColors.text.inverted,
          },
        },
        MuiFormControlLabel: {
          label: {
            '&$disabled': {
              color: currentColors.background.bg3,
            },
          },
        },
        MuiInputAdornment: {
          positionStart: {
            marginLeft: `${SPACING[1]}px`,
            marginRight: 0,
          },
        },
        MuiTablePagination: {
          select: {
            display: 'none',
          },
        },
        MuiInputLabel: {
          outlined: {
            position: 'relative',
            transform: 'translate(14px, 36px) scale(1)',
            '&$shrink': {
              transform: 'none',
            },
          },
          root: {
            color: currentColors.text.body1,
          },
          filled: {
            color: currentColors.text.body2,
          },
          formControl: {
            color: currentColors.text.body1,
            transform: 'none',
            fontSize: 12,
            fontWeight: 600,
            '& + .MuiInput-formControl': {
              marginTop: '20px',
            },
          },
          shrink: {
            transform: 'none',
          },
        },

        MuiOutlinedInput: {
          adornedStart: {
            paddingLeft: 0,
            '& input': {
              paddingLeft: `${SPACING[1]}px`,
            },
          },
          root: {
            backgroundColor: 'transparent',
            borderColor: currentColors.stroke,
            border: '1px solid',
            borderRadius: 5,
            '&:hover $notchedOutline': {
              borderStyle: 'none',
            },
          },
          notchedOutline: {
            borderStyle: 'none',
          },
          input: {
            padding: `${SPACING[1]}px ${SPACING[2]}px`,
          },
        },
        MuiRadio: {
          colorSecondary: {
            '&$disabled': {
              color: currentColors.background.bg3,
            },
          },
        },
        MuiInput: {
          root: {
            color: currentColors.text.body1,
            padding: `${SPACING[1]}px ${SPACING[2]}px`,
            backgroundColor: currentColors.background.bg1,
            borderRadius: BORDER_RADIUS,
            borderColor: currentColors.stroke,
            border: '1px solid',
            '&$focused, &:hover': {
              backgroundColor: currentColors.background.bg1,
              color: currentColors.text.body1,
            },
            '&$disabled': {
              backgroundColor: currentColors.background.bg3,
              color: currentColors.text.body2,
              borderBottom: '1px solid',
            },
          },
          underline: {
            '&:before': {
              display: 'none',
            },
          },
        },
        MuiFormGroup: {
          root: {
            flexDirection: 'row',
          },
        },
        MuiFormHelperText: {
          root: {
            color: currentColors.text.body2,
          },
        },
        MuiButton: {
          root: {
            color: currentColors.text.inverted,
            fontWeight: 400,
            padding: `0px ${SPACING[3]}px`,
            backgroundColor: currentColors.accent.primary,
            '&:hover': {
              backgroundColor: currentColors.text.body2,
            },
            '&.Mui-disabled': {
              color: currentColors.text.body2,
              backgroundColor: currentColors.background.bg3,
            },
          },
          text: {
            padding: `0px ${SPACING[3]}px`,
            borderRadius: BORDER_RADIUS,
            background: 'transparent',
            color: currentColors.text.body1,
            '& .MuiButton-label': {
              fontWeight: 400,
              fontSize: FONT_SIZES[0],
            },
            '&.Mui-disabled': {
              background: 'transparent',
              color: currentColors.text.body2,
            },
          },
          containedPrimary: {
            backgroundColor: currentColors.accent.primary,
          },
          contained: {
            padding: `0px ${SPACING[3]}px`,
            height: '40px',
            color: currentColors.text.inverted,
            backgroundColor: currentColors.accent.primary,
            '&:hover': {
              backgroundColor: darken(0.1, currentColors.accent.primary),
            },
            '&.Mui-disabled': {
              background: currentColors.background.bg3,
              color: 'white',
            },
            '& .MuiButton-label': {
              fontWeight: 400,
              fontSize: FONT_SIZES[0],
            },
          },
          outlined: {
            //backgroundColor: 'transparent',
            borderColor: currentColors.stroke,
            backgroundColor: currentColors.background.bg1,
            color: currentColors.text.body1,
            fontWeight: 400,
            '& .MuiButton-label': {
              fontWeight: '400 !important',
              fontSize: '0.85rem',
            },
            '&:hover': {
              backgroundColor: currentColors.background.bg2,
            },
            '&:disabled': {
              backgroundColor: 'transparent',
              borderColor: currentColors.text.body2,
            },
          },
          label: {
            fontSize: FONT_SIZES[2],
            fontWeight: 600,
          },
        },
        MuiTableCell: {
          body: {
            borderColor: currentColors.stroke,
          },
          stickyHeader: {
            backgroundColor: currentColors.background.bg1,
            color: currentColors.text.body2,
            padding: '8px 16px',
            fontWeight: 600,
          },
          footer: {
            fontWeight: 600,
            borderBottom: 'none',
            backgroundColor: currentColors.background.bg2,
          },
        },
        MuiTableFooter: {},
        MuiLink: {
          root: {
            cursor: 'pointer',
          },
        },
        MuiMenuItem: {
          root: {
            color: currentColors.text.body1,
          },
        },
        MuiTab: {
          root: {
            fontSize: '0.875rem',
            minWidth: '0 !important',
            padding: '6px 0',
            marginRight: '12px',
            fontWeight: 600,
          },
        },
        MuiSelect: {
          iconOutlined: {},
          outlined: {
            borderColor: currentColors.stroke,
            border: '1px solid',
            backgroundColor: currentColors.background.bg1,
            padding: `${SPACING[1]}px ${SPACING[2]}px`,
            '&:hover, &:focus': {
              backgroundColor: currentColors.background.bg1,
            },

            '&.Mui-disabled': {
              color: currentColors.text.body2,
              backgroundColor: currentColors.background.bg1,
            },
          },
        },
        MuiList: {
          root: {
            borderRadius: 0,
          },
        },
        MuiListItem: {
          root: {
            '&.Mui-selected': {
              backgroundColor: currentColors.background.bg3,
            },
          },
        },
        MuiBreadcrumbs: {
          li: {
            '& a, & a p': {
              color: currentColors.accent.primary,
            },
            '& > p': {
              color: currentColors.text.body1,
            },
          },
          separator: {
            color: currentColors.text.body2,
          },
        },
        MuiDivider: {
          root: {
            backgroundColor: currentColors.text.body2,
          },
        },
        MuiSwitch: {
          track: {
            backgroundColor: currentColors.text.body2,
            opacity: 1,
          },
        },
        MuiCheckbox: {
          root: {
            color: currentColors.text.body1,
          },
          colorSecondary: {
            '&.Mui-checked': {
              color: currentColors.accent.primary,
            },
            '&.Mui-disabled': {
              color: currentColors.background.bg3,
            },
          },
        },
        MuiPaper: {
          root: {
            backgroundColor: currentColors.background.bg1,
            color: currentColors.text.body1,
            '&:focus': {
              outline: 'none',
            },
          },
        },
        MuiStepper: {
          root: {
            color: currentColors.text.inverted,
            backgroundColor: currentColors.accent.primary,
          },
        },
        MuiStepConnector: {
          line: {
            borderColor: currentColors.text.inverted,
          },
        },
        MuiStepLabel: {
          label: {
            color: currentColors.text.inverted,
            fontSize: '14px',
            '&$active': {
              fontWeight: 600,
              color: currentColors.text.inverted,
            },
            '&$completed': {
              color: currentColors.text.inverted,
            },
          },
          iconContainer: {
            border: '1px solid',
            borderColor: currentColors.text.inverted,
            color: currentColors.text.inverted,
            paddingRight: 0,
            marginRight: 12,
            borderRadius: 20,
            '&$active': {
              borderColor: currentColors.text.inverted,
            },
          },
        },
        MuiStepIcon: {
          text: {
            fill: currentColors.text.body2,
          },
          root: {
            color: 'transparent',
            '&$active': {
              color: currentColors.accent.primary,
              '& text': {
                fill: currentColors.text.inverted,
              },
            },
            '&$completed': {
              color: currentColors.text.inverted,
            },
          },
          completed: {
            color: currentColors.text.inverted,
          },
        },
        // @ts-ignore
        MuiAlert: {
          standardError: {
            backgroundColor: currentColors.red,
            color: currentColors.text.inverted,
          },
          standardSuccess: {
            backgroundColor: currentColors.green,
            color: currentColors.text.inverted,
          },
          icon: {
            color: '#fff !important',
          },
        },
        MuiAutocomplete: {
          outlined: {
            borderColor: currentColors.stroke,
            border: '1px solid',
            color: currentColors.text.inverted,
            backgroundColor: currentColors.background.bg2,
            padding: `${SPACING[1]}px ${SPACING[2]}px`,
            borderRadius: BORDER_RADIUS,

            '&:hover, &:focus': {
              backgroundColor: currentColors.background.bg1,
            },

            '&.Mui-disabled': {
              color: currentColors.text.body2,
              backgroundColor: currentColors.background.bg1,
            },
            height: 40,
          },

          groupLabel: {
            backgroundColor: currentColors.background.bg1,
            fontWeight: 600,
          },
          input: {
            marginBottom: 6,
          },
        },
        ...getPickerOverrides(currentColors.accent.primary),
      },
    },
    fiFI,
  );
};

export const updateTheme = (primaryColor?: string) => {
  theme = getTheme(primaryColor);
  return theme;
};

export let theme = getTheme();

export default theme;
