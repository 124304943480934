/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_INVOICING_FEE_VAT } from '../../constants';
import { useStores } from '../../stores/index';
import { getGroupedCartItems } from '../../utils/cartUtils';
import { getPrettySum } from '../../utils/i18nUtils';
import MaterialTable from '../MaterialTable/MaterialTable';

interface OrderOverviewProps {
  displayVat?: boolean;
  invoicingFee?: number;
}

export const OrderOverview: FC<OrderOverviewProps> = observer(
  ({ displayVat, invoicingFee }) => {
    const {
      cartStore: { cart, sum },
    } = useStores();

    const { t } = useTranslation();

    const header = (id: string) => t(`orders.add.cartModal.table.${id}`);

    const prepareColumns = (cols: any[], addPriceColumn: boolean) => {
      let newCols = [...cols];

      if (addPriceColumn) {
        newCols.push({
          Header: 'price',
          Cell: ({ row: { original } }: any) => {
            if (original.product?.explicitlyInvoiced)
              return t('products.explicitlyInvoiced');
            return getPrettySum(original.price, true);
          },
        });
      }

      newCols = newCols.map((col, index) => ({
        ...col,
        ...{
          width: index === 0 ? '60%' : undefined,
          Header: header(col.Header),
        },
      }));

      return newCols;
    };

    const VAT_TABLE_COLUMNS = [
      {
        accessor: 'vatPercentage',
        Header: t('orders.vatTable.vatPercentage'),
        Cell: ({ row: { original } }: any) => `${original.vatPercentage} %`,
      },
      {
        accessor: 'withoutVat',
        Header: t('orders.vatTable.withoutVat'),
        Cell: ({ row: { original } }: any) =>
          getPrettySum(original.valueSum - original.vatValueSum, true),
      },
      {
        accessor: 'vatValueSum',
        Header: t('orders.vatTable.vatValueSum'),
        Cell: ({ row: { original } }: any) =>
          getPrettySum(original.vatValueSum, true),
      },
      {
        accessor: 'valueSum',
        Header: t('orders.vatTable.valueSum'),
        Cell: ({ row: { original } }: any) =>
          getPrettySum(original.valueSum, true),
      },
    ];

    const TICKET_COLUMNS = useMemo(() => {
      return prepareColumns(
        [
          {
            Header: 'name',
            accessor: 'id',
          },
          {
            Header: 'quantity',
            accessor: 'quantity',
          },
        ],
        true,
      );
    }, [header]);

    const SKYBOX_COLUMNS = useMemo(() => {
      return prepareColumns(
        [
          {
            Header: 'name',
            accessor: 'skyboxInfo.name',
          },
          {
            Header: 'quantity',
            accessor: 'amount',
            Cell: () => 1,
          },
        ],
        true,
      );
    }, [header]);

    const ORDER_FEE_COLUMNS = useMemo(() => {
      return prepareColumns(
        [
          {
            accessor: 'title',
            Header: 'name',
          },
          {
            Header: 'quantity',
            accessor: 'quantity',
          },
          {
            accessor: 'price',
            Header: 'price',
            Cell: ({ row: { original } }: any) =>
              getPrettySum(original.price, true),
          },
        ],
        false,
      );
    }, []);

    const groupedTickets = useMemo(() => {
      if (!cart?.tickets?.length) return [];
      return getGroupedCartItems(
        cart.tickets,
        'eventimTicketData.TicketTypeText',
      );
    }, [cart]);

    const otherFees = [
      {
        id: 'orderFee',
        title: t('orders.orderFee'),
        price: cart?.orderFee || 0,
        quantity: 1,
      },
    ];

    let vats = [...(cart?.vat || [])];

    if (invoicingFee) {
      otherFees.push({
        id: 'invoicingFee',
        title: t('owner.invoicingFee'),
        price: invoicingFee,
        quantity: 1,
      });

      const vatSameAsInvoice = vats.find(
        (vatObj) => vatObj.vatPercentage === DEFAULT_INVOICING_FEE_VAT,
      );

      if (vatSameAsInvoice) {
        const newValueSum = vatSameAsInvoice.valueSum + invoicingFee;
        const newVatValueSum = (DEFAULT_INVOICING_FEE_VAT / 100) * newValueSum;

        vats = vats.filter(
          (vatObj) => vatObj.vatPercentage !== DEFAULT_INVOICING_FEE_VAT,
        );
        vats.push({
          vatPercentage: DEFAULT_INVOICING_FEE_VAT,
          valueSum: newValueSum,
          vatValueSum: newVatValueSum,
        });
      } else {
        vats.push({
          vatPercentage: DEFAULT_INVOICING_FEE_VAT,
          valueSum: invoicingFee,
          vatValueSum: (DEFAULT_INVOICING_FEE_VAT / 100) * invoicingFee,
        });
      }
    }

    const cartTotalSum = useMemo(
      () => getPrettySum(sum + (invoicingFee || 0), true),
      [cart, invoicingFee],
    );

    return (
      <Box>
        <Box p={3} mb={5}>
          <Typography variant="h3">
            {t('orders.add.cartModal.total', { sum: cartTotalSum })}
          </Typography>
        </Box>
        {!!cart?.tickets?.length && (
          <Box mb={3}>
            <Typography variant="h4">
              {t('orders.add.cartModal.tickets')}
            </Typography>
            <Box mt={2}>
              <MaterialTable data={groupedTickets} columns={TICKET_COLUMNS} />
            </Box>
          </Box>
        )}

        {!!cart?.purchaseSkyboxes?.length && (
          <Box mb={3}>
            <Typography variant="h4">
              {t('orders.add.cartModal.skybox')}
            </Typography>
            <Box mt={2}>
              <MaterialTable
                data={cart.purchaseSkyboxes}
                columns={SKYBOX_COLUMNS}
              />
            </Box>
          </Box>
        )}

        {!!cart?.orderFee && (
          <Box mb={3}>
            <Typography variant="h4">
              {t('orders.add.cartModal.otherFees')}
            </Typography>
            <Box mt={2}>
              <MaterialTable data={otherFees} columns={ORDER_FEE_COLUMNS} />
            </Box>
          </Box>
        )}

        {displayVat && (
          <Box maxWidth={500}>
            <MaterialTable
              data={vats.filter((vat: any) => vat.vatValueSum !== 0)}
              columns={VAT_TABLE_COLUMNS}
            />
          </Box>
        )}
      </Box>
    );
  },
);
