import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../stores';
import colors from '../../styles/colors';
import { ISkyboxWithEventSkybox, MasterSkyboxStatus } from '../../types/Skybox';
import {
  getMasterSkyboxStatus,
  getSkyboxStatus,
} from '../../utils/commonUtils';
import { getUserDisplayName } from '../../utils/getUserDisplayName';
import { getPrettySum } from '../../utils/i18nUtils';
import { ColorBox } from '../ColorBox/ColorBox';
import { InfoTooltip } from '../InfoTooltip';

interface Props {
  skybox: ISkyboxWithEventSkybox;
  showInBox?: boolean;
}

const WarningIcon = (
  <>
    <FontAwesomeIcon color={colors.gold7} icon={faExclamationTriangle} />
    <Box mr={2} />
  </>
);

const StatusTextWrapper = ({
  children,
  showInBox,
}: {
  children: React.ReactNode;
  showInBox: Props['showInBox'];
}) => {
  return showInBox ? (
    <ColorBox color="red" whiteText mb={3}>
      {children}
    </ColorBox>
  ) : (
    <>{children}</>
  );
};

const StatusText = ({
  isOwner,
  skybox,
  myCompanyId,
  text,
  masterStatus,
}: {
  isOwner: boolean;
  skybox: ISkyboxWithEventSkybox;
  myCompanyId: string | undefined;
  text: string;
  masterStatus: MasterSkyboxStatus;
}) => {
  const { t } = useTranslation();

  if (isOwner && skybox.ownerCompanyId !== myCompanyId) {
    return (
      <InfoTooltip tooltipText={t('events.crossUseTooltip')}>
        <Typography>{text}</Typography>
      </InfoTooltip>
    );
  }
  return (
    <Box>
      <Box component="span" display="flex" alignItems="center">
        {masterStatus === 'waiting' && WarningIcon}
        {text}
      </Box>
    </Box>
  );
};

export const SkyboxStatusText: React.FC<Props> = observer(
  ({ skybox, showInBox }) => {
    const { t } = useTranslation();
    const {
      eventStore: { event },
      settingsStore: { myCompanyData },
      authStore: { isOwner },
    } = useStores();

    if (!event) {
      return null;
    }

    const status = getSkyboxStatus(skybox);
    const masterStatus = getMasterSkyboxStatus(status);

    const translationParams = useMemo(() => {
      const price = skybox.eventSkybox
        ? skybox.eventSkybox.price
        : skybox.defaultPrice;

      const cartExpiresAt = skybox.purchase?.expiresAt
        ? moment(skybox.purchase?.expiresAt)
        : null;

      const minutesToExpiration = cartExpiresAt
        ? Math.ceil(moment.duration(cartExpiresAt.diff(moment())).asMinutes())
        : null;

      if (status === 'targeted_rental') {
        const deadline = moment(skybox.eventSkybox.rentalDeadline).startOf(
          'day',
        );
        return {
          daysToGo: deadline.diff(moment().startOf('day'), 'days'),
        };
      }

      return {
        price: getPrettySum(price, true),
        minutesToExpiration,
        name: getUserDisplayName(skybox.reservedFor),
      };
    }, [skybox, status]);

    const text = useMemo(() => {
      let str = t(`events.statusDescription.${status}`, translationParams);

      if (isOwner && skybox.ownerCompanyId !== myCompanyData?.id) {
        return str;
      }

      const noTicketOrder = masterStatus === 'waiting' && status !== 'in_cart';
      if (noTicketOrder) {
        str = `${t('events.noTicketOrder')}. ${str}`;
      }
      return str;
    }, [
      isOwner,
      skybox,
      myCompanyData,
      masterStatus,
      status,
      t,
      translationParams,
    ]);

    if (!text) {
      return null;
    }

    return (
      <StatusTextWrapper showInBox={showInBox}>
        <StatusText
          isOwner={isOwner}
          skybox={skybox}
          myCompanyId={myCompanyData?.id}
          text={text as string}
          masterStatus={masterStatus}
        ></StatusText>
      </StatusTextWrapper>
    );
  },
);
