import { darken, makeStyles } from '@material-ui/core';
import colors from '../../styles/colors';

const BIG_IMAGE = 128;
const SMALL_IMAGE = 100;

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: 200,
    width: 200,
    backgroundColor: colors.text.body1,
    borderRadius: 5,
    position: 'relative',
  },
  title: {
    position: 'absolute',
    left: -1,
    top: -1,
    // backgroundColor: props => props.theme.palette.secondary.main,
    color: colors.text.body1,
    fontSize: 10,
    fontWeight: 'bold',
    padding: '3px 10px',
    zIndex: 999,
  },
  button: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 250ms',
    fontWeight: 400,
    background: colors.background.bg2,
    borderRadius: 5,
    minHeight: BIG_IMAGE,
    minWidth: BIG_IMAGE,
    maxHeight: BIG_IMAGE,
    maxWidth: BIG_IMAGE,
    fontSize: '14px',
    color: colors.text.body2,
    border: '1px solid',
    borderColor: colors.stroke,

    '&:hover': {
      backgroundColor: darken(colors.background.bg2, 0.1),
    },
  },
  image: {
    width: 40,
    height: 40,
  },
  label: {
    fontSize: 14,
    marginTop: 8,
  },
  hiddenInput: {
    height: 0.1,
    width: 0.1,
    position: 'absolute',
    zIndex: -1,
    opacity: 0,
  },
  previewContainer: {
    minHeight: BIG_IMAGE,
    minWidth: BIG_IMAGE,
    maxHeight: BIG_IMAGE,
    maxWidth: BIG_IMAGE,
    position: 'relative',
    '& button': {
      top: 20,
      right: 10,
    },
  },
  disabledOverlay: {
    position: 'absolute',
    minHeight: BIG_IMAGE,
    minWidth: BIG_IMAGE,
    maxHeight: BIG_IMAGE,
    maxWidth: BIG_IMAGE,
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  preview: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    minHeight: BIG_IMAGE,
    minWidth: BIG_IMAGE,
    maxHeight: BIG_IMAGE,
    maxWidth: BIG_IMAGE,
  },
  deleteButton: {
    position: 'absolute',
    border: 'none',
    width: 30,
    height: 30,
    borderRadius: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none',
    transition: 'all 250ms',
    zIndex: 9999,
    background: colors.background.bg2,
    '&:hover': {
      background: darken(colors.background.bg2, 0.1),
    },
  },
  icon: {
    width: 20,
    color: theme.palette.secondary.main,
    height: 20,
  },
  previewGridImage: {
    minHeight: SMALL_IMAGE,
    minWidth: SMALL_IMAGE,
    maxHeight: SMALL_IMAGE,
    maxWidth: SMALL_IMAGE,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    position: 'relative',
    '& button': {
      top: 10,
      right: 10,
    },
  },
  previewUrlsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));
