import { makeAutoObservable, runInAction } from 'mobx';
import CustomerService from '../services/CustomerService';
import { StoreState } from '../types/Common';
import { ICustomer } from '../types/Customer';

interface ICustomerStore {
  isLoading: boolean;
  state: StoreState;
  currentCustomer?: ICustomer;
  getCurrentCustomer: () => void;
}

class CustomerStoreModel implements ICustomerStore {
  state: StoreState = 'Idle';

  currentCustomer: ICustomerStore['currentCustomer'] = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  get logo() {
    return this.currentCustomer?.logoAsset?.source;
  }

  getCurrentCustomer = async () => {
    this.state = 'Loading';
    try {
      const customer = await CustomerService.getCustomer();
      runInAction(() => {
        if (!customer.hasSkyboxes) {
          throw new Error(
            `Fetched customer data for customer: ${customer.slug} which does not have skyboxes enabled`,
          );
        }
        this.currentCustomer = customer;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };
}

const CustomerStore = new CustomerStoreModel();

export default CustomerStore;
