/* eslint-disable react-hooks/exhaustive-deps */
import { Box, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorBox } from '../../ColorBox/ColorBox';
import { OrderOverview } from '../../OrderOverview.tsx/OrderOverview';
import { CommonModal } from '../CommonModal';

interface CartModalProps {
  isOpen: boolean;
  onClose: any;
}

const useStyles = makeStyles({
  modalContent: {
    maxHeight: '70vh',
    overflowY: 'auto',
  },
});

export const CartModal: FC<CartModalProps> = observer(({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <CommonModal
      open={isOpen}
      onClose={onClose}
      type="normal"
      title={t('orders.add.cartModal.title')}
    >
      <Box className={classes.modalContent}>
        <ColorBox p={3} color="red" mb={3} whiteText>
          {t('orders.add.cartModal.removeProductModal.body')}
        </ColorBox>
        <OrderOverview />
      </Box>
    </CommonModal>
  );
});
