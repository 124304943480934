import { PaginationDTO } from '../types/Common';
import { GetOrdersDTO, ResendOrderConfirmationDTO } from '../types/Order';
import { DownloadSkyboxTicketDTO } from '../types/Skybox';
import { api, getPath } from './api';

const API_PATHS = Object.freeze({
  downloadSkyboxTicket:
    '/customer/{customerId}/skybox-ticket/{itemId}/{secondaryItemId}?noaccept=1',
  openMobileTicket:
    '/customer/{customerId}/skybox-ticket/{itemId}/{secondaryItemId}/mobile?noaccept=1',
  getOrder: '/customer/{customerId}/order/{itemId}',
  receipt: '/customer/{customerId}/order/{itemId}/receipt',
  myOrder: '/customer/{customerId}/skybox/my/reservations/{itemId}',
  order: '/customer/{customerId}/skybox/reservations/{itemId}',
  orders: '/customer/{customerId}/order/',
  report: '/customer/{customerId}/report/purchaseSkyboxes',
  skyboxOrders: '/customer/{customerId}/skybox/reservations',
  resendOrderConfirmation: '/customer/{customerId}/purchase/resendTickets',
});

const triggerUrlDownload = (url: string, filename: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

interface GetSkyboxOrdersDto extends PaginationDTO {
  eventStartDate?: string;
  eventEndDate?: string;
}

class OrderServiceModel {
  getOrder = async (purchaseId: string) =>
    api.get(getPath(API_PATHS.getOrder, purchaseId));

  getOrders = async (params?: GetOrdersDTO) => {
    if (params?.paymentMethod === 'all') delete params.paymentMethod;
    return api.get(getPath(API_PATHS.orders), { params });
  };
  getSkyboxOrders = async (params: GetSkyboxOrdersDto) =>
    api.get(getPath(API_PATHS.skyboxOrders), { params });

  getSkyboxOrder = async (purchaseId: string) =>
    api.get(getPath(API_PATHS.order, purchaseId));

  getMySkyboxOrder = async (purchaseId: string) =>
    api.get(getPath(API_PATHS.myOrder, purchaseId));

  downloadSkyboxTicket = async ({
    purchaseId,
    code,
  }: DownloadSkyboxTicketDTO) =>
    api.get(getPath(API_PATHS.downloadSkyboxTicket, purchaseId, code), {
      responseType: 'arraybuffer',
    });

  getMobileSkyboxTicketHref = ({
    purchaseId,
    code,
  }: {
    purchaseId: string;
    code: string;
  }) => {
    return getPath(`/api${API_PATHS.openMobileTicket}`, purchaseId, code);
  };

  getReport = async (params: any) => {
    return api.get(getPath(API_PATHS.report), { params });
  };

  resendOrderConfirmation = async (data: ResendOrderConfirmationDTO) =>
    api.post(getPath(API_PATHS.resendOrderConfirmation), data);

  getReceiptPdf = async (purchaseId: string) => {
    const response = await api.get<string>(
      getPath(API_PATHS.receipt, purchaseId),
      { responseType: 'text' },
    );
    triggerUrlDownload(response.data, `${purchaseId}-invoice.pdf`);
  };
}

const OrderService = new OrderServiceModel();

export default OrderService;
