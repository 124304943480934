import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonModal } from '../Modals/CommonModal';

interface SkyboxMapProps {}

const useStyles = makeStyles({
  map: {
    maxWidth: '100%',
  },
  mapContainer: {
    maxHeight: '80vh',
    overflowY: 'auto',
  },
});

export const SkyboxMap: React.FC<SkyboxMapProps> = observer(() => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  const onToggleClick = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Box>
      <Button variant="text" onClick={onToggleClick}>
        <FontAwesomeIcon icon={faEye} />
        <Box ml={1} component="span">
          {t('common.showSkyboxMap')}
        </Box>
      </Button>

      <CommonModal
        maxWidth={600}
        type="normal"
        open={modalOpen}
        onClose={handleClose}
      >
        <Box className={classes.mapContainer}>
          <img
            className={classes.map}
            src="/images/skyboxmap.png"
            alt="Skybox map"
          />
        </Box>
      </CommonModal>
    </Box>
  );
});
