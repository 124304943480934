import '@fontsource/inter';
import 'moment/locale/fi';
import ReactDOM from 'react-dom';
import App from './App';
import { initLang } from './i18n/index';
import { stores, StoresProvider } from './stores/Stores';

initLang('fi');

ReactDOM.render(
  <StoresProvider value={stores}>
    <App />
  </StoresProvider>,
  document.getElementById('root'),
);
