const AUTH_TOKEN_KEY = 'skyboxAuthToken';
let sessionAuthToken: string | undefined;

export const isLocalStorageAvailable = () => !!window.localStorage;

export const setAuthToken = (token: string) => {
  if (isLocalStorageAvailable()) {
    window.localStorage.setItem(AUTH_TOKEN_KEY, token);
  } else {
    sessionAuthToken = token;
  }
};

export const getAuthToken = () => {
  if (isLocalStorageAvailable()) {
    return window.localStorage.getItem(AUTH_TOKEN_KEY) || undefined;
  }
  return sessionAuthToken;
};
