import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import { Box, CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Sentry from '@sentry/react';
import fiDateLocale from 'date-fns/locale/fi';
import { createBrowserHistory } from 'history';
import countries from 'i18n-iso-countries';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Router } from 'react-router-dom';
import 'typeface-inter';
import './app.css';
import { ToastProvider } from './components/Toast';
import { ENV, SENTRY_ENABLED } from './constants';
import { initLang } from './i18n';
import Routes from './Routes';
import { useStores } from './stores';
import { theme, updateTheme } from './styles';

initLang('fi');

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/fi.json'));
countries.registerLocale(require('i18n-iso-countries/langs/sv.json'));
const history = createBrowserHistory();

if (SENTRY_ENABLED) {
  Sentry.init({
    dsn: 'https://63699bf18d826d4ac954c7587c00fcca@o4506353584832512.ingest.sentry.io/4506388548812800',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/api\.qa\.livex\.fi/,
          /^https:\/\/api\.prod\.livex\.fi/,
        ],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.1,
    environment: ENV,
  });
}

const App = observer(() => {
  const {
    customerStore: { currentCustomer },
  } = useStores();
  const customerTheme = useMemo(() => {
    if (currentCustomer) {
      return updateTheme(currentCustomer?.settings?.theme?.primary);
    }
    return theme;
  }, [currentCustomer]);

  return (
    <MuiThemeProvider theme={customerTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiPickersUtilsProvider locale={fiDateLocale} utils={DateFnsUtils}>
          <CssBaseline />
          <Box className="App">
            <Router history={history}>
              <Routes />
            </Router>
          </Box>
          <ToastProvider />
        </MuiPickersUtilsProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
});

export default App;
