import { Box, Button, makeStyles, Radio, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { Radios, TextField } from 'mui-rff';
import { FC, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { AddOrderLayout } from '../../components/Layout';
import { useStore } from '../../stores/index';
import { theme } from '../../styles';
import colors from '../../styles/colors';
import { getUserDisplayName } from '../../utils/getUserDisplayName';
import { customerFormValidate, NewCustomerForm } from './NewCustomerForm';

interface OrderFormCustomerScreenProps {}

const useStyles = makeStyles({
  existingUsersList: {
    padding: 0,
    listStyleType: 'none',
    margin: 0,
  },
  existingUser: {
    backgroundColor: colors.background.bg2,
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    width: '100%',
    display: 'flex',
  },
  listItem: {},
});

export const OrderFormCustomerScreen: FC<OrderFormCustomerScreenProps> =
  observer(() => {
    const [valid, setValid] = useState(true);
    const { searchUsers, queriedUsers, setQueriedUsers } =
      useStore('userStore');
    const {
      claimShoppingCartForUser,
      createUserAndClaimCart,
      setActiveStep,
      activeStep,
    } = useStore('cartStore');
    const [formValues, setFormValues] = useState({});
    const [selectedUser, setSelectedUser] = useState<string>();
    const { t } = useTranslation();
    const classes = useStyles();

    // TODO: MVP solution
    const TEMPORARY_DATA = {
      password: 'TemporaryPassword2021!',
      termsAndConditionsAccepted: true,
    };

    const onConfirm = async () => {
      try {
        if (selectedUser) {
          await claimShoppingCartForUser(selectedUser);
        } else {
          const values = { ...formValues, ...TEMPORARY_DATA };
          await createUserAndClaimCart(values as any);
        }
        setActiveStep(activeStep + 1);
      } catch (error) {
        console.error(error);
      }
    };

    const RADIO_BUTTONS = [
      {
        label: t('orders.add.customer.existingUser'),
        value: 'true',
      },
      {
        label: t('orders.add.customer.newUser'),
        value: 'false',
      },
    ];

    const initialValues = { existingUser: 'true' };
    const onSubmit = () => {};

    const onSearch = (email: string) => {
      searchUsers({ email });
    };
    const onFormChange = ({ valid: formValid, values }: any) => {
      if (formValid !== valid) setValid(formValid);
      setFormValues(values);
    };

    const onExistingUserChange = (userId: string) => {
      setSelectedUser(userId);
    };

    const onSearchKeyUp = (e: any) => {
      if (e.key === 'Enter') {
        onSearch(e.target.value);
      }
    };

    const renderFoundUsers = () => {
      if (queriedUsers && !queriedUsers.length) {
        return (
          <Box bgcolor={colors.background.bg2} mt={3} p={3}>
            <Typography>
              {t('orders.add.customer.noExistingUsersFound')}
            </Typography>
          </Box>
        );
      }
      return (
        <Box component="ul" className={classes.existingUsersList} marginTop={3}>
          {queriedUsers?.map((user) => (
            <Box component="li" className={classes.listItem} key={user.id}>
              <Box className={classes.existingUser}>
                <Radio
                  name="user"
                  value={user.id}
                  onChange={() => onExistingUserChange(user.id)}
                  checked={selectedUser === user.id}
                />
                <Box ml={2}>
                  <Typography variant="h4">
                    {getUserDisplayName(user)}
                  </Typography>
                  <Typography>{user.email}</Typography>
                  <Typography>{user.phone}</Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      );
    };

    return (
      <AddOrderLayout
        title={t('orders.add.customer.title')}
        onConfirm={onConfirm}
        confirmDisabled={!valid && !selectedUser}
      >
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={customerFormValidate}
          render={({ form, visited, values, errors, valid }) => {
            if (visited?.existingUser) {
              const newVal = values.existingUser === 'true' ? 'false' : 'true';
              form.resetFieldState('existingUser');
              setQueriedUsers(undefined);
              setSelectedUser(undefined);
              // @ts-ignore
              form.reset({ existingUser: newVal });
            }
            return (
              <Box maxWidth={500} component="form">
                <Radios name="existingUser" data={RADIO_BUTTONS} />

                {values.existingUser === 'true' && (
                  <Box width="100%" mt={3}>
                    <TextField
                      name="email"
                      fullWidth
                      onKeyUp={onSearchKeyUp}
                      label={t('orders.add.customer.searchLabel')}
                      placeholder={t('orders.add.customer.searchPlaceholder')}
                    />
                    <Box mt={2}>
                      <Button
                        disabled={!valid && !!errors.email}
                        onClick={() => onSearch(values.email || '')}
                      >
                        {t('common.find')}
                      </Button>
                    </Box>
                  </Box>
                )}
                {values.existingUser === 'false' && <NewCustomerForm />}
                <FormSpy
                  onChange={onFormChange}
                  subscription={{ valid: true, values: true }}
                />
                {values.existingUser === 'true' && renderFoundUsers()}
              </Box>
            );
          }}
        />
      </AddOrderLayout>
    );
  });
