const RED = '#D32029';
const GREEN = '#6ABE39';
const WHITE = '#ffffff';
const GREY1 = '#141414';
const GREY7 = '#7D7D7D';
const GREY9 = '#dbdbdb';
const GREY10 = '#F8F8F8';

// These are default colors, which are not overwritten by customer
const colors = {
  background: {
    bg1: WHITE,
    bg2: GREY10,
    bg3: GREY9,
  },
  text: {
    body1: GREY1,
    body2: GREY7,
    inverted: WHITE,
  },
  stroke: GREY9,
  blue3: '#15395B',
  geekblue3: '#1C2755',
  red3: '#58181C',
  red5: '#A61d24',
  red7: '#E84749',
  gold7: '#E8B339',
  green: GREEN,
  red: RED,
};

export default colors;
