import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useEffect } from 'react';
import { useStores } from '../../stores';
import colors from '../../styles/colors';
import { NotificationBar } from '../NotificationBar';
import { Seo } from '../Seo/Seo';
import Spinner from './MainSpinner';
import SideMenu from './SideMenu';
import TopBar from './TopBar';
interface LayoutProps {
  headerText?: string;
  headerElementsRight?: ReactNode;
  headerElementsBottom?: ReactNode;
  headerElementsTop?: ReactNode;
  customHeader?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  layout: {
    color: colors.text.body1,
    backgroundColor: colors.background.bg2,
  },
  content: {
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  pageHeader: {
    paddingTop: theme.spacing(3),
    color: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  pageHeaderMainContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerElements: {
    display: 'flex',
    width: 'auto',
    minWidth: 0,
    justifyContent: 'space-between',
  },
  headerElementsRight: {
    display: 'flex',
    width: 'auto',
    minWidth: 0,
    justifyContent: 'flex-end',
  },
  contentContainer: {
    flex: '1 1 auto',
    minHeight: 'auto',
    height: 'auto',
    margin: theme.spacing(3),
    backgroundColor: colors.background.bg1,
    position: 'relative',
    borderRadius: 5,
    boxShadow: '4px 4px 8px rgba(0, 16, 60, 0.1)',
  },
  headerText: {},
}));

export const Layout: React.FC<LayoutProps> = observer(
  ({
    headerText,
    headerElementsRight,
    headerElementsTop,
    headerElementsBottom,
    children,
    customHeader,
  }) => {
    const {
      authStore: { currentUser, isOwner },
      customerStore: { currentCustomer, getCurrentCustomer },
      commonStore: { isAuthenticated },
      bulletinStore: {
        getBulletins,
        isLoading: bulletinsLoading,
        allBulletins,
      },
    } = useStores();
    const classes = useStyles();

    useEffect(() => {
      if (!currentCustomer && isAuthenticated) {
        getCurrentCustomer();
      }
    }, [currentCustomer, getCurrentCustomer, isAuthenticated]);

    useEffect(() => {
      if (isOwner && !bulletinsLoading && allBulletins === undefined) {
        getBulletins();
      }
    }, [isOwner, allBulletins, bulletinsLoading, getBulletins]);

    return (
      <>
        <Box id="layout" className={classes.layout}>
          {currentUser && currentCustomer ? (
            <>
              <TopBar />
              <Box mt="60px">
                <Box display="flex" flex="1 1">
                  <SideMenu />
                  <Box
                    id="content"
                    className={classes.content}
                    component="main"
                  >
                    <NotificationBar />
                    {customHeader}
                    <div className={classes.contentContainer}>
                      {headerText && (
                        <Box px={3}>
                          <div className={classes.pageHeader}>
                            {headerElementsTop}
                            <Box className={classes.pageHeaderMainContent}>
                              <Typography
                                className={classes.headerText}
                                variant="h1"
                              >
                                {headerText}
                              </Typography>
                              {headerElementsRight && (
                                <div className={classes.headerElements}>
                                  <div className={classes.headerElementsRight}>
                                    {headerElementsRight}
                                  </div>
                                </div>
                              )}
                            </Box>
                          </div>
                          {headerElementsBottom}
                        </Box>
                      )}
                      <Spinner />
                      {children}
                    </div>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Grid id="content" item xs={12} className={classes.content}>
              {children}
            </Grid>
          )}
        </Box>
        <Seo />
      </>
    );
  },
);
