import { faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import sortBy from 'lodash.sortby';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import { Checkboxes } from 'mui-rff';
import { FC, useMemo, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../stores/index';
import { SPACING } from '../../styles';
import colors from '../../styles/colors';
import { ICalendarSkyboxUsage } from '../../types/Calendar';
import { ISkybox } from '../../types/Skybox';
import { getPrettyTimePeriod } from '../../utils/i18nUtils';
import { ReservationCalendarOrderModal } from './ReservationCalendarOrderModal';

export interface ReservationsListProps {
  date?: Date;
  onSelectEvent: any;
}

const useStyles = makeStyles((theme) => ({
  day: {
    flex: 1,
    height: '100%',
    borderRight: `1px solid ${colors.stroke}`,
    '&.today': {
      backgroundColor: colors.background.bg2,
    },
    '&.today .date-container p': {
      fontWeight: 600,
    },
  },
  dayContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
    border: `1px solid ${colors.stroke}`,
    minHeight: 300,
    overflowY: 'auto',
  },
  dateContainer: {
    textTransform: 'capitalize',
    textAlign: 'center',
    borderBottom: `1px solid ${colors.stroke}`,
  },
  reservation: {
    padding: `${SPACING[1]}px ${SPACING[2]}px`,
    backgroundColor: colors.red3,
    borderRadius: 5,
    cursor: 'pointer',
  },
  event: {
    padding: `${SPACING[1]}px ${SPACING[2]}px`,
    backgroundColor: colors.geekblue3,
    borderColor: colors.geekblue3,
    border: '1px solid',
    borderRadius: 5,
    cursor: 'pointer',
  },
  reservationHeading: {
    fontSize: '0.75rem',
    display: 'block',
    color: colors.text.inverted,
  },
  dayReservationContainer: {},
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: colors.text.inverted,
  },
  date: {
    fontSize: '0.70rem',
    color: colors.text.inverted,
  },
  spinner: {
    color: theme.palette.secondary.main,
  },
}));

interface ReservationListItem {
  startDate: Moment;
  endDate: Moment;
  type: 'reservation' | 'order';
  order?: ICalendarSkyboxUsage;
  skybox?: ISkybox;
}

export const ReservationsList: FC<ReservationsListProps> = observer(
  ({ date, onSelectEvent }) => {
    const {
      calendarStore: {
        allCalendarReservations,
        calendarSkyboxUsage,
        isLoading,
      },
    } = useStores();
    const classes = useStyles();
    const [formValues, setFormValues] = useState<{ filter: string[] }>({
      filter: ['reservations', 'orders'],
    });
    const { t } = useTranslation();
    const [selectedOrder, setSelectedOrder] = useState<
      ICalendarSkyboxUsage | undefined
    >();

    const calendarDays = useMemo(() => {
      const days = [] as { date: string; events: ReservationListItem[] }[];
      for (let i = 0; i < 7; i += 1) {
        const startOfWeek = moment(date).startOf('week');
        const dayDate = startOfWeek.add(i, 'days').toISOString();
        days.push({
          date: dayDate,
          events: [],
        });
      }

      (allCalendarReservations || []).map((reservation) => {
        const date = moment(reservation.startDate).startOf('day').toISOString();
        const index = days.findIndex((obj) => obj.date === date);

        days[index]?.events?.push({
          ...reservation,
          ...{
            type: 'reservation',
            startDate: moment(reservation.startDate),
            endDate: moment(reservation.endDate),
            mapId: reservation.skybox?.mapId,
          },
        } as ReservationListItem);

        return null;
      });

      (calendarSkyboxUsage || []).map((order: ICalendarSkyboxUsage) => {
        const date = moment(order.event?.date)
          .startOf('day')
          .toISOString();
        const index = days.findIndex((obj) => obj.date === date);

        days[index]?.events?.push({
          order,
          ...{
            type: 'order',
            startDate: moment(order.event.date),
            endDate: moment(order.event.date).add(4, 'hours'),
            mapId: order.skybox?.mapId,
          },
        } as ReservationListItem);

        return null;
      });

      return days.map((day) => {
        day.events = sortBy(
          day.events,
          'startDate',
          'order.skyboxName',
          'skybox.name',
        );
        return day;
      });
    }, [allCalendarReservations, calendarSkyboxUsage, date]);

    const onSubmit = () => {};

    const onFormChange = ({ values }: any) => {
      if (values !== formValues) {
        setFormValues(values);
      }
    };

    const controls = ['reservations', 'orders'].map((str) => ({
      value: str,
      label: t(`calendar.reservationsList.label.${str}`),
    }));

    const onOrderModalClose = () => {
      setSelectedOrder(undefined);
    };

    /**
     * User clicks an order item on the list
     */
    const onOrderClick = (order: ICalendarSkyboxUsage) => () => {
      setSelectedOrder(order);
    };

    return (
      <Box pl={3} pr={3}>
        <Box className="no-print">
          <Form
            initialValues={formValues}
            onSubmit={onSubmit}
            render={() => (
              <Box component="form" mb={1}>
                <Checkboxes name="filter" data={controls} />
                <FormSpy
                  onChange={onFormChange}
                  subscription={{ values: true }}
                />
              </Box>
            )}
          />
        </Box>
        <Box className={classes.dayContainer}>
          {calendarDays.map(({ date, events }) => {
            const isCurrentDate = moment(date).isSame(new Date(), 'day');

            return (
              <Box className={`${classes.day} ${isCurrentDate ? 'today' : ''}`}>
                <Box
                  className={`${classes.dateContainer} date-container`}
                  p={1}
                >
                  <Typography>{moment(date).format('DD dd ')}</Typography>
                </Box>
                <Box className={classes.dayReservationContainer} p={1}>
                  {isLoading && (
                    <Box mt={3} justifyContent="center" display="flex">
                      <CircularProgress className={classes.spinner} />
                    </Box>
                  )}
                  {events.map((item) => {
                    if (
                      item.type === 'reservation' &&
                      formValues.filter?.includes('reservations')
                    ) {
                      return (
                        <Box
                          className={classes.reservation}
                          mb={1}
                          onClick={() => onSelectEvent(item)}
                        >
                          <Typography>
                            <Box
                              className={classes.reservationHeading}
                              component="strong"
                              mr="3px"
                            >
                              {item.skybox?.mapId}
                            </Box>
                            <Box className={classes.date} component="span">
                              {getPrettyTimePeriod(
                                item.startDate.toISOString(),
                                item.endDate.toISOString(),
                              )}
                            </Box>
                          </Typography>
                        </Box>
                      );
                    }
                    if (
                      item.type === 'order' &&
                      formValues.filter?.includes('orders')
                    ) {
                      return (
                        <Box
                          className={classes.event}
                          mb={1}
                          onClick={onOrderClick(item.order!)}
                        >
                          <Typography>
                            <Box
                              className={classes.reservationHeading}
                              component="strong"
                              mr="3px"
                            >
                              {item?.order?.skybox?.mapId}
                            </Box>
                            <Box className={classes.date} component="span">
                              {getPrettyTimePeriod(
                                item.startDate.toISOString(),
                                item.endDate.toISOString(),
                              )}
                            </Box>
                            <Box component="span" className={classes.footer}>
                              <FontAwesomeIcon icon={faTags} />
                            </Box>
                          </Typography>
                        </Box>
                      );
                    }

                    return null;
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
        {selectedOrder && (
          <ReservationCalendarOrderModal
            open
            order={selectedOrder}
            onClose={onOrderModalClose}
          />
        )}
      </Box>
    );
  },
);
